import { appAPI } from "../../httpClient";

export const getComments = async (requestId:string, assetId:string,params: any) => {
  const res = await appAPI.get(`/comments`, { params });
  return {
    meta: res.data.data.meta,
    data: res.data.data.values
  }
};

export const postComment = async (requestId:string, assetId:string,payload: any) => {
  return appAPI.post<any>(`/comments`, payload);
};
