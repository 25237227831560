export const DRAWER_WIDTH = 300;

export const ROLES = [
    {id:'super_admin',value:'super_admin',label:'Super Admin'},
    {id:'admin',value:'admin',label:'Admin'},
    {id:'appraiser',value:'appraiser',label:'Appraiser'},
    {id:'guest',value:'guest',label:'Guest'}
];

export const STATUS_FILTERS = [
    {id:'all',value:'All',label:'All'},
    {id:'new',value:'New',label:'New'},
    {id:'in-progress',value:'In progress',label:'In progress'},
    {id:'awaiting-feedback',value:'Awaiting feedback',label:'Awaiting feedback'},
    {id:'completed',value:'Completed',label:'Completed'},
];

export const USER_STATUS_FILTERS = [
    {id:'all',value:'all',label:'All'},
    {id:'acive',value:'active',label:'Active'},
    {id:'blocked',value:'blocked',label:'Blocked'},
    {id:'pending',value:'pending',label:'Pending'},
    {id:'locked',value:'locked',label:'Locked'},
];

export const ASSET_HEADERS = [
    {id:'make', value:'make',label:'Make'},
    {id:'year', value:'year',label:'Year'},
    {id:'model', value:'model',label:'Model'},
    {id:'assetType', value:'assetType',label:'Asset Type'},
    {id:'vin', value:'vin',label:'VIN'},
    {id:'serial', value:'serial',label:'Serial'},
    {id:'sticker', value:'sticker',label:'Sticker ID'},
    {id:'status', value:'status',label:'Status'},
];

export const STATUS_FILTERS_MAP:any = {
    'New':'new',
    'In progress':'in_progress',
    'Awaiting feedback':'awaiting_feedback',
    'Completed': 'completed'
}

export const REQUEST_SEARCH_TYPES = [
    {id:'Request',value:'Request',label:'Request'},
    {id:'Asset',value:'Asset',label:'Asset'}
];

export const VALUATION_TYPES = [
    {id:'flv',value:'flv',label:'FLV'},
    {id:'fmv',value:'fmv',label:'FMV'},
    {id:'olv',value:'olv',label:'OLV'},
    {id:'mov',value:'mov',label:'MOV'},
    // {id:'desktop',value:'desktop',label:'Desktop valuation'}
];

export const APPROACHED_TO_VALUE_TYPES = [
    {id:'sales_comparison',value:'sales_comparison',label:'Sales Comparison'},
    {id:'cos',value:'cost',label:'Cost'},
    {id:'income',value:'income',label:'Income'}
];

export const DEFAULT_PROFILE = "person-placeholder.png";

export const QUALIFICATIONS = [
    "I have been in the auction industry for 4+ years and have been focused on values and market analysis over the past 9+ years.",
    "Over 10,000 hours experience on various equipment types",
    "Frequently led training on industry specific equipment to help with continuing education and to train employees on how the equipment operates and what to look for when inspecting/taking pictures.",
    "Attended numerous industry trade shows, dealership visits, seminars, webinars, listened to equipment podcasts, read valuation books and publications for 25+ years, many industry specific tours and field trips.",
    "Held previous positions including: fleet manager, used equipment manager and territory sales representative",
    "Graduate of Nashville Auto Diesel College (Lincoln Technical School)",
    "Attended courses from Caterpillar, Komatsu, Deere, Metso in equipment sales and repair",
    "Construction Equipment Economics Course 2011",
    "Member of NAA (National Auctioneers Association)",
    "Attended 40hr GPPA training Dec 2022",
    "USPAP 2020-21 certificate"
];
  
export const USPAP_CERTIFICATION = [
    "The statements of fact contained in this report are true and correct.",
    "The reported analyses, opinions, and conclusions are limited only by the reported assumptions and limiting conditions and are my personal, impartial, and unbiased professional analyses, opinions and conclusions.",
    "I have no present or prospective interest in the property that is the subject of this report and no personal interest with respect to the parties involved.",
    "I have performed no services, as an appraiser or in any other capacity, regarding the property that is the subject of this report within the three-year period immediately preceding the agreement to perform this assignment.",
    "I have no bias with respect to the property that is the subject of this report or to the parties involved with this assignment.",
    "My engagement in this assignment was not contingent upon developing or reporting predetermined results.",
    "Compensation for completing this assignment is not contingent upon the development or reporting of a predetermined value or direction in value that favors the cause of the client, the amount of the value opinion, the attainment of a stipulated result, or the occurrence of a subsequent event directly related to the intended use of this appraisal.",
    "My analyses, opinions, and conclusions were developed, and this report has been prepared, in conformity with the 2020-2021 Uniform Standards of Professional Appraisal Practice (USPAP).",
    "In the case of an onsite report, I have inspected the subject property personally. In the case of a desktop report, I have relied on ethical practices of the client to provide accurate information.",
    "No one provided significant assistance as to research and valuing the property to me as the person certifying this report."
];
  
export const USPAP_TERMS = [
    { term: "ADVOCACY", definition: "Representing the cause or interest of another, even if that cause or interest does not necessarily coincide with one's own beliefs, opinions, conclusions, or recommendations" },
    { term: "APPRAISAL", definition: "(noun) The act or process of developing an opinion of value; an opinion of value. (Adjective) of or pertaining to appraising and related functions such as appraisal practice or appraisal services" },
    { term: "APPRAISAL CONSULTING", definition: "The act or process of developing an analysis, recommendation, or opinion to solve a problem, where an opinion of value is a component of the analysis leading to the assignment results" },
    { term: "APPRAISAL PRACTICE", definition: "Valuation services performed by an individual acting as an appraiser, including but not limited to appraisal, appraisal review, or appraisal consulting" },
    { term: "APPRAISER", definition: "One who is expected to perform valuation services competently and in a manner that is independent, impartial, and objective" },
    { term: "APPRAISER PEERS", definition: "Other appraisers who have expertise and competency in the same or a similar type of assignment" },
    { term: "APPRECIATION", definition: "Increase in value due to increase in cost to reproduce, value over the cost, or value at some specified earlier point in time brought about by greater demand, improved economic conditions, increasing price levels, reversal of depreciating environmental trends, improved transportation facilities, direction of community or area growth, or other factors" },
    { term: "ASSIGNMENT", definition: "A valuation service provided as a consequence of an agreement between an appraiser and a client" },
    { term: "ASSIGNMENT RESULTS", definition: "An appraiser's opinions and conclusions developed specific to an assignment" },
    { term: "ASSUMPTION", definition: "That which is taken to be true" },
    { term: "BIAS", definition: "A preference or inclination that precluded an appraiser's impartiality, independence, or objectivity in an assignment" },
    { term: "BUSINESS ENTERPRISE", definition: "An entity pursuing an economic activity" },
    { term: "CLIENT", definition: "The party or parties who engage an appraiser (by employment or contract) in specific assignment" },
    { term: "CONFIDENTIAL INFORMATION", definition: "Information that is either: Identified by the client as confidential when providing it to an appraiser and that is not available from any other source; or Classified as confidential or private by applicable law or regulation" },
    { term: "COST", definition: "The amount required to create, produce, or obtain property" },
    { term: "DEPRECIATION", definition: "A loss of utility and hence value from any cause. An effect caused by physical deterioration and/or obsolescence" },
    { term: "DESKTOP APPRAISAL", definition: "An appraisal of limited scope whereby the appraiser estimates the value of the equipment from his desk with only a listing supplied to him, and without benefit of a physical inspection of that equipment. Critical assumptions are made as to condition, age, location, physical appearance, ease of removal, and other pertinent factors that may affect value. An aggregate value is determined in a limited appraisal report including all limiting conditions, critical assumptions, and definitions of values, approaches to value, conducted and written to comply with all standards of USPAP." },
    { term: "ECONOMIC OBSOLESCENCE", definition: "Impairment of desirability of useful life arising from factors external to the property, such as economic forces or environmental changes which affect supply-demand relationships in the market. Loss in the use and value of a property arising from the factors of economic obsolescence is to be distinguished from loss in value from physical deterioration and functional obsolescence, both of which are inherent in the property. Also referred to as Location or Environmental Obsolescence" },
    { term: "EXTRAORDINARY ASSUMPTION", definition: "An assumption, directly related to a specific assignment, which, if found to be false, could alter the appraiser's opinion or conclusions" },
    { term: "FEASIBILITY ANALYSIS", definition: "A study of the cost-benefit relationship of an economic endeavor" },
    { term: "FUNCTIONAL OBSOLESCENCE", definition: "Impairment of functional capacity or efficiency. Functional obsolescence reflects the loss in value brought about by such factors as overcapacity, inadequacy, and changes in the art that affect the property item itself or its relation with other elements comprising a larger property. The inability of a structure to perform adequately the function for which it is currently employed" },
    { term: "HIGHEST AND BEST USE", definition: "That reasonable and probable use that will support the highest present value, as defined, as of the effective date of the appraisal" },
    { term: "HYPOTHETICAL CONDITION", definition: "That which is contrary to what exists but is supposed for the purpose of analysis" },
    { term: "INTANGIBLE PROPERTY (INTANGIBLE ASSETS)", definition: "Non-physical assets, including but not limited to franchises, trademarks, patents, copyrights, goodwill, equities, securities, and contracts as distinguished from physical assets such as facilities and equipment" },
    { term: "INTENDED USE", definition: "The use or uses of an appraiser's reported appraisal, appraisal review, or appraisal consulting assignment opinions and conclusions, as identified by the appraiser based on communication with the client at the time of the assignment" },
    { term: "INTENDED USER", definition: "The client and any other party as identified, by name or type, as users of the appraisal, appraisal review, or appraisal consulting report by the appraiser on the basis of communication with the client at the time of the assignment" },
    { term: "JURISDICTIONAL EXCEPTION", definition: "An assignment condition that voids the force of a part or parts of USPAP, when compliance with part or parts of USPAP is contrary to law or public policy applicable to the assignment" },
    { term: "MARKET PRICE", definition: "The amount actually paid, or to be paid, for a property in a particular transaction differs from market value in that it is an accomplished or historic fact, whereas market value is and remains an estimate until proven. Market price involves no assumption of prudent conduct by the parties, absence of undue stimulus, or any other condition basic to the market value concept" },
    { term: "MARKET VALUE", definition: "A type of value, stated as an opinion, that presumes the transfer of property (i.e., a right of ownership or a bundle of such rights), as of a certain date, under specific conditions set forth in the definition of the term identified by the appraiser as applicable in an appraisal" },
    { term: "PERSONAL PROPERTY", definition: "Identifiable tangible objects that are considered by the general public as being 'personal' - for example, furnishings, artwork, antiques, gems and jewelry, collectibles, machinery and equipment; all tangible property that is not classified as real estate" },
    { term: "PRESENT VALUE", definition: "The current monetary value. It is the today's cash lump sum, which represents the current value of the right to collect future payments. It is the discounted value of aggregate future payments" },
    { term: "PRICE", definition: "The amount asked, offered, or paid for property" },
    { term: "REPORT", definition: "Any communication, written or oral, of an appraisal, appraisal review, or appraisal consulting service that is transmitted to the client upon completion of an assignment" },
    { term: "SCOPE OF WORK", definition: "The amount and type of information researched and the analysis applied in an assignment. Scope of work includes, but is not limited to, the following: the degree to which the property is inspected or identified; the extent of research into physical or economic factors that could affect the property; the extent of data research; and the type and extent of analysis applied to arrive at opinions or conclusions" },
    { term: "SIGNATURE", definition: "Personalized evidence indicating authentication of the work performed by the appraiser and the acceptance of the responsibility for content, analyses and conclusions in the report" },
    { term: "SUPPLEMENTAL STANDARDS", definition: "Requirements issued by government agencies, government sponsored enterprises, or other entities that establish public policy which add to the purpose, intent and content of the requirements in USPAP, that have a material effect on the development and reporting of assignment results" },
    { term: "VALUE", definition: "The monetary relationship between properties and those who buy, sell, or use those properties" },
    { term: "VALUATION PROCESS", definition: "Services pertaining to aspects of property value" },
    { term: "WORKFILE", definition: "Documentation necessary to support an appraiser's analysis, opinions, and conclusions" }
];
  
export const DISCLOSURES = [
    "All facts and data set forth in this report are true and correct to the best of the appraiser’s knowledge. The fee for this report is not contingent upon the values reported. There have been no guarantees associated with this fee and no liability can be intimated or assumed in any manner.",
    "Neither the appraiser nor any officer or employee of Purple Wave has any financial interest in the property appraised, unless specifically noted.",
    "Physical condition in most instances has been determined by observation or indication by others. Any unknown conditions existing at the time of inspection could alter the value. No responsibility is assumed for latent defects of any nature whatsoever which may affect value, nor for any expertise required to disclose such conditions.",
    "In preparing this opinion, Purple Wave estimates the values of the subject property by assessing the fair market value. In determining the value Purple Wave may consider a number of factors, including but not limited to the condition of the asset including any recent repairs or upgrades, the age of the asset, recent used sales records for comparable assets, recent technological advancements that may have rendered the asset obsolete, the availability of the asset in the market and market conditions.",
    "The opinion is prepared and reviewed by a GPPA certified USPAP appraiser with over 10,000 hours of appraisal time. Experts on specific types or makes of assets may be consulted to determine the condition and specific features of an asset. Technological sources of data about asset details may be consulted as part of preparing the opinion."
];