import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { SearchInput } from './inputs';
import { appAPI } from '../httpClient';

interface DebouncedSearchProps {
    name?: string;
    apiEndpoint?: string;
    onResultsFetched?: (results: any) => void;
    refetchOnClear?:boolean;
}

const fetchSearchResults = async (apiEndpoint: string, query: any) => {
    const response = await appAPI.get(apiEndpoint, {
        params: { 'search[name]':query,limit:10 },
    });
    return {meta:response.data.data.meta,data:response.data.data.values};
};

const DebouncedSearch: React.FC<DebouncedSearchProps> = ({
    name,
    apiEndpoint,
    onResultsFetched,
    refetchOnClear=true
}) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [triggerSearch, setTriggerSearch] = useState(false);

    const { data: results, isFetching, isError,error } = useQuery({
        queryKey: ['searchResults', searchQuery],
        queryFn: () => fetchSearchResults(apiEndpoint!, searchQuery),
        enabled: triggerSearch, 
    });

    useEffect(() => {
        if (onResultsFetched && !isFetching && results !== undefined) {
            console.log('onRes called', results);
            onResultsFetched(results);
        }
    }, [results, isFetching, onResultsFetched]);

    const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
        if(event.target.value === '' && refetchOnClear){
            setTriggerSearch(true);
        }
    };

    const handleSearchSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        if (searchQuery.trim() !== '') {
            setTriggerSearch(true);
        } 
    };

    useEffect(() => {
        if (triggerSearch) {
            setTriggerSearch(false);
        }
    }, [triggerSearch]);

    //remove after api integration
    useEffect(()=>{
        if(onResultsFetched && isError){
            onResultsFetched([]);
        }
    },[error, isError, onResultsFetched]);

    return (
        <Box>
            <form onSubmit={handleSearchSubmit}>
                <SearchInput
                    name={name}
                    value={searchQuery} // Bind input value to state
                    onChange={handleSearchInputChange}
                    isLoading={isFetching}
                />
                <button type="submit" style={{ display: 'none' }}>Search</button> {/* Hidden submit button */}
            </form>
        </Box>
    );
};

export default DebouncedSearch;
