import { Box, IconButton, Typography } from "@mui/material";
import { MaterialSymbol } from "react-material-symbols";
import { IColDef } from "../../table/tableInterfaces";
import {CSS} from '@dnd-kit/utilities';
import { useSortable } from "@dnd-kit/sortable";

interface Props{
    col: IColDef;
    columnConfig?: {columnHide:any, pinnedColumns:{left:string[]; right:string[]}};
    handleOpenPinMenu: (event: React.MouseEvent<HTMLElement, MouseEvent>, field: string) => void;
    toggleColumnVisibility: (field:string)=>void;
}
const APEnhanceColumnItem:React.FC<Props> = ({col, columnConfig, handleOpenPinMenu,toggleColumnVisibility}) => {
    const {attributes, listeners, setNodeRef, transform, transition} = useSortable({
        id: col.id!,
      });
      const style = {
        transition,
        transform: CSS.Translate.toString(transform)
      };
    return (
        <Box
            ref={setNodeRef}
            style={style}
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                bgcolor: "#F9FBFE",
                p: "18px",
                borderBottom: "1px solid #ddd",
                mb: 1,
            }}
        >
            <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
                <MaterialSymbol {...listeners} {...attributes} icon="drag_indicator" size={24} style={{color:`${'#919EAB'}`}}/>
                <Typography><span style={{marginRight:'8px'}}>{col.id}</span>{col.headerName}</Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: "14px" }}>
                {/* Pin Menu */}
                <IconButton onClick={(e) => handleOpenPinMenu(e, col.field)}>
                <MaterialSymbol 
                    style={{zIndex:0}}
                    icon={columnConfig?.pinnedColumns?.left.includes(col.field) 
                    ? "arrow_back"
                    : columnConfig?.pinnedColumns?.right.includes(col.field) 
                    ? "arrow_forward"
                    : "push_pin"
                    } 
                    size={20} 
                />
                </IconButton>

                {/* Visibility Toggle */}
                <IconButton onClick={() => toggleColumnVisibility(col.field)}>
                <MaterialSymbol icon={Object.keys(columnConfig?.columnHide).includes(col.field) ? "visibility_off" : "visibility"} size={20} />
                </IconButton>
            </Box>
        </Box>
    )

}

export default APEnhanceColumnItem;