import { Page, Text, View, Document, StyleSheet, Image, Link } from '@react-pdf/renderer';
// import DOMPurify from 'dompurify';
// import { formatDate, getCurrentDate } from '../../../utils/helpers';
// import UspapAllImage from '../uspap/UspapAllImage';
import StandardAllImage from './StandardAllImage';
import StandardSummary from './StandardSummary';

interface Props{
    reportResponse: any;
  }
const StandardAllImagesDocument = ({ reportResponse }:Props) => {
  
  const defaultValuation =
  reportResponse.request.total_estimates &&
  reportResponse.request.total_estimates.find((es: any) => es.estimate_type === "fmv") ||
  reportResponse.request.total_estimates?.[0];

  /*
  const sanitizeHTML = (html: string): string => {
    return DOMPurify.sanitize(html);  // Returns a sanitized string
  };

  const renderHTML = (html: string) => {
    const sanitizedHTML = sanitizeHTML(html);
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = sanitizedHTML;
  
    return Array.from(tempDiv.childNodes).map((node, index) => {
      if (node.nodeName === 'P') {
        // Render <p> as plain text
        return <Text key={index}>{node.textContent}</Text>;
      } else if (node.nodeName === 'STRONG') {
        // Render <strong> as bold text
        return <Text key={index} style={styles.bold}>{node.textContent}</Text>;
      } else if (node.nodeName === 'EM') {
        // Render <em> as italic text
        return <Text key={index} style={{ fontStyle: 'italic' }}>{node.textContent}</Text>;
      } else if (node.nodeName === 'UL') {
        // Render <ul> as a list
        return (
          <View key={index} style={{ marginTop: '10px', display:'flex', flexDirection:'column' }}>
            {Array.from(node.childNodes).map((liNode, liIndex) => {
              if (liNode.nodeName === 'LI') {
                // Render each <li> as a bullet point
                return (
                  <View key={liIndex} style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 10,
                    marginTop: '10px'
                  }}>
                    <Text>•</Text>
                    <Text>{liNode.textContent}</Text>
                  </View>
                );
              }
              return null; // Handle other cases as needed
            })}
          </View>
        );
      }
      return null; // Handle other cases as needed
    });
  };
  */

  const styles = StyleSheet.create({
    pageCenter: {
      padding: 40,
      flexDirection: "column",
      alignItems: "center",
      fontSize:12,
      fontFamily: 'Times-Roman',
      color: '#2c3033',
    },
    sectionCenterAlign:{
      display:'flex',
      flexDirection:'column',
      alignItems:'center'
    },
    contact: {
      textAlign: "center",
      fontSize: 12,
      marginTop: 10,
    },
    link: {
      color: "#0073e6",
      textDecoration: "none",
    },
      page:{
          paddingTop: 35,
          paddingBottom: 65,
          paddingHorizontal: 35,
          flexDirection: "column",
          alignItems: "flex-start",
          fontSize:12,
          color: '#2c3033',
      },
      logo: {
        width: "80%",
        height: "65%",
        marginBottom: 20,
      },
      bold: {
        color:'#000000',
        fontWeight:'heavy'
      },
      flexRow:{
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
      },
      justifyBetween:{
        justifyContent:'space-between'
      },
      width100:{
        width:'100%'
      },
      pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
      },
    });

  return (
    <Document>
      <Page size="A4" style={styles.pageCenter}>
            <Image
            src="/assets/images/logo.png"
            style={styles.logo}
            />
            <View style={[styles.sectionCenterAlign]}>
              <Text>Prepared By:</Text>
              <Text style={styles.bold}>Purple Wave Appraisal Services</Text>
              <Text>825 Levee Dr.</Text>
              <Text>Manhattan, KS 66502</Text>
            </View>
            <View style={[styles.sectionCenterAlign, {marginTop:20}]}>
              <Text style={styles.bold}>{reportResponse?.request.manager?.name}</Text>
              <Text>Appraiser</Text>
            </View>
            <View style={styles.sectionCenterAlign}>
              <Text style={styles.bold}>Purple Wave Appraisal Services</Text>
              <Text>825 Levee Dr., Manhattan, KS 66502</Text>
              <View style={[styles.flexRow, {gap:20}]}>
                <View style={styles.flexRow}>
                  <Text style={styles.bold}>
                    Toll Free:
                  </Text>
                  <Text>
                    866-608-9283
                  </Text>
                </View>
                <Text>|</Text>
                <View style={styles.flexRow}>
                  <Text style={styles.bold}>
                    Local: 
                  </Text>
                  <Text>
                    785-537-7653
                  </Text>
                </View>
              </View>
              <Text style={styles.contact}>
                Email:{" "}
                <Link style={styles.link} src="mailto:appraisals@purplewave.com">
                appraisals@purplewave.com
                </Link>{" "}
                | Website:{" "}
                <Link
                style={styles.link}
                src="https://www.purplewave.com/appraisals"
                >
                www.purplewave.com/appraisals
                </Link>
              </Text>
            </View>
            <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                `${pageNumber} / ${totalPages}`
            )} fixed />
      </Page>

      <Page size="A4" style={styles.page}>
        <View style={[styles.flexRow, styles.width100, {marginBottom:10, alignItems:'flex-start', justifyContent:'flex-end'}]}>
          {/* <Text>Purplewave</Text> */}
          <Image
            src="/assets/images/logo.png"
            style={{width:'40px', height:'40px'}}
          />
        </View>
        <StandardSummary report={reportResponse}/>
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
              `${pageNumber} / ${totalPages}`
          )} fixed />
      </Page>

      <Page size="A4" style={styles.page}>
        <View style={[styles.flexRow, styles.width100, {marginBottom:10, alignItems:'flex-start', justifyContent:'flex-end'}]}>
          {/* <Text>Purplewave</Text> */}
          <Image
            src="/assets/images/logo.png"
            style={{width:'40px', height:'40px'}}
          />
        </View>

        <View style={[styles.flexRow, styles.justifyBetween, styles.width100, { alignItems:'flex-start'}]}>
          <View>
            <Text>EQUIPMENT OWNER</Text>
            <Text style={styles.bold}>{reportResponse.request.customer_name}</Text>
          </View>
          <View style={{display:'flex', flexDirection:'column', alignItems:'flex-end'}}>
            <Text>TOTAL {defaultValuation?.estimate_type.toUpperCase()}</Text>
            <Text style={styles.bold}>$ {defaultValuation?.total_value}</Text>
          </View>
        </View>
        {reportResponse.assets && <StandardAllImage assets={reportResponse.assets}/>}
          
        <Text style={[styles.pageNumber,{}]} render={({ pageNumber, totalPages }) => (
          `${pageNumber} / ${totalPages}`
        )} fixed />
      </Page>
    

    </Document>
  )
};

export default StandardAllImagesDocument;