import { useState } from "react";
import { Box, Typography, Popover, Avatar, List, ListItem, useTheme, Badge } from "@mui/material";

interface Props {
  data: { avatar: string; name: string; value: string }[];
}

const APOtherEstimates: React.FC<Props> = ({ data }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const theme = useTheme();

  if (!data || data.length === 0) return null;

  const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
    if (data.length > 1) {
      setAnchorEl(event.currentTarget);
      setIsPopoverOpen(true);
    }
  };

  return (
    <Box sx={{ height: "100%", alignContent: "center" }}>
      {data.length === 1 ? (
        <Box
          sx={{ display: "flex", gap: "8px" }}
        >
          <Avatar src={data[0].avatar} alt={data[0].name} sx={{width:"32px", height:"32px"}}/>
          <Box>
            <Typography sx={{ color: "#86888A", fontSize: "10px" }}>{data[0].name}</Typography>
            <Typography sx={{ color: "#343434", fontSize: "14px" }}>{data[0].value}</Typography>
          </Box>
        </Box>
      ) : (
        <Box
          onMouseEnter={handleMouseEnter}
          sx={{ display: "flex", gap: "8px" }}
        >
          <Badge badgeContent={data.length} color="secondary">
            <Avatar src={data[0].avatar} alt={data[0].name} sx={{width:"32px", height:"32px"}}/>
          </Badge>
          <Box>
            <Typography sx={{ color: "#86888A", fontSize: "10px" }}>{data[0].name}</Typography>
            <Typography sx={{ color: "#343434", fontSize: "14px" }}>{data[0].value}</Typography>
          </Box>
        </Box>
      )}

      <Popover
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onClose={() => setIsPopoverOpen(false)}
        disableAutoFocus
        disableEnforceFocus
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        slotProps={{
          paper: {
            sx: {
              borderRadius: "16px",
              padding: "8px",
              boxShadow: `2px 2px 4px ${theme.palette.black}10`,
            },
          },
        }}
      >
        <Box onMouseEnter={() => setIsPopoverOpen(true)} onMouseLeave={() => setIsPopoverOpen(false)}>
          <List>
            {data.map((item, index) => (
              <ListItem key={index} sx={{ display: "flex", alignItems: "center", padding: "8px", gap: "8px" }}>
                <Avatar src={item.avatar} alt={item.name} sx={{ width: "30px", height: "30px" }} />
                <Typography sx={{ color: "#86888A", fontSize: "12px" }}>{item.name}</Typography>
                <Typography sx={{ color: theme.palette.primary.main, fontSize: "12px", fontWeight: "bold" }}>
                  {` - ${item.value}`}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Box>
      </Popover>
    </Box>
  );
};

export default APOtherEstimates;
