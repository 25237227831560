import { Box } from "@mui/material";
import Introduction from "./Introduction";
import IntendedUse from "./IntendedUse";
import RestrictedType from "./RestrictedType";
import WorkScope from "./WorkScope";
import ApproachesToValue from "./ApproachesToValue";
import MarketConditionAnalyze from "./MarketConditionAnalyze";
import Sources from "./Sources";
import ExtraodinaryAssumptions from "./ExtraodinaryAssumptions";
import HypotheticalConditions from "./HypotheticalConditions";
import LimitingConditions from "./LimitingConditions";
import { useForm } from "react-hook-form";
import { APIconButton, DefaultButton } from "../../components/buttons";
import { MaterialSymbol } from "react-material-symbols";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { generateReport, updateReport } from "./reportQueries";
import { toast } from "react-toastify";
import { handleError } from "../../utils/errorHandler";
import { useEffect, useState } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import UspspReportPreview2 from "./uspap/UspapReportPreview2";
import { IReportForm } from "./reportInterface";
import UspapDocument from "./uspap/UspapDocument";
import { RadioInput } from "../../components/inputs";
import { USPAP_REPORT_VARIANTS } from "../../data/selectData";
import APDivider from "../../components/APDivider";
import { pdf } from "@react-pdf/renderer";
import { LoadingAction } from "../../components/dialogs";
import Worker from "worker-loader!../../workers/networkWorker.ts";
import useNavigationConfirmationHook from "../../components/dialogs/useNavigationConfirmationHook";

const ReportsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // const reportResponse = location.state.reportResponse;
  const [showPreview, setShowPreview] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [reportResponse, setReportResponse] = useState(
    location.state.reportResponse
  );

  const handleClick = () => {
    setShowPreview(false);
    setBreadcrumbItems((prev) => {
      const newBreadcrumbs = prev.slice(0, -1);
      return newBreadcrumbs;
    });
  };

  const [breadcrumbItems, setBreadcrumbItems] = useState([
    { label: "Back" },
    { label: "USPAP Report", onClick: handleClick },
  ]);

  const {
    control,
    reset,
    getValues,
    formState: { dirtyFields },
  } = useForm();
  const { isPending: isLoading, mutate: postReportMutation } = useMutation({
    mutationKey: ["postReportMutation"],
    mutationFn: ({ formValues }: { formValues: any }) =>
      updateReport(reportResponse.request.id, formValues),
    onSuccess: (res, variables) => {
      console.log(variables);
      if (res.status === 204) {
        toast.error("An error occured during request creation");
      } else {
        console.log(res);
        setReportResponse(res.data);
        reset({ ...res.data }, { keepValues: true });
        toast.success("Report saved successfully");
      }
    },
    onError: (error) => {
      setShowModal(false);
      handleError(error);
    },
  });

  // the navigation confirmation hooks
  const { confirmDialogue } = useNavigationConfirmationHook({
    isDirty: Object.keys(dirtyFields).length > 0,
  });

  const { isPending: isGenerating, mutate: generateReportMutation } =
    useMutation({
      mutationKey: ["generateReportMutation"],
      mutationFn: ({ pdfBlob }: { pdfBlob: Blob }) =>
        generateReport(reportResponse.request.id, pdfBlob),
      onSuccess: (res, variables) => {
        console.log(variables);
        if (res.status === 204) {
          toast.error("An error occured during request creation");
        } else {
          toast.success(
            "Report generated successfully. Kindly check your email."
          );
          setShowModal(false);
        }
      },
      onError: handleError,
    });

  const handleReportReview = () => {
    setShowPreview(true);
    setBreadcrumbItems((prev) => [...prev, { label: "USPAP Preview" }]);
  };
  const handleGeneratePdf = async () => {
    try {
      setShowModal(true);
      const doc = (
        <UspapDocument
          reportResponse={reportResponse}
          report={getValues() as IReportForm}
        />
      );
      const blob = await pdf(doc).toBlob();
      generateReportMutation({ pdfBlob: blob });
      // updateInstance(<UspapDocument reportResponse={reportResponse} report={getValues() as IReportForm} />);
      // console.log(instance);

      // Download the PDF
      // setShowModal(false);
      // const link = document.createElement("a");
      // link.href = URL.createObjectURL(blob);
      // link.download = "Report.pdf";
      // link.click();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const dateKeys: string[] = [
      "effective_date",
      "report_date",
      "inspection_date",
    ];
    const data = {
      ...reportResponse,
      restricted: reportResponse.restricted ? "true" : "false",
    };
    // console.log('data: ',data);
    Object.keys(data).forEach((key: any) => {
      if (dateKeys.includes(key) && data[key] !== null) {
        data[key] = data[key].split("T")[0];
      }
    });
    // setReportVariant(data.report_variant);
    reset({ ...data });
  }, [reportResponse, reset]);

  useEffect(() => {
    const worker = new Worker();
    console.log(reportResponse);
    if (reportResponse.report_variant === "all_images") {
      console.log(reportResponse.assets);
      worker.onmessage = (event) => {
        const { status, data, error } = event.data;
        if (status === "success") {
          console.log("worker response: ", data);
          // setData(data);
        } else {
          console.log("an error occured: ", error);
          // setError(error);
        }
      };

      // Send a request to the worker
      worker.postMessage({
        url: "https://jsonplaceholder.typicode.com/posts",
        assets: reportResponse.assets,
        options: { method: "GET" },
      });
    }

    // Handle messages from the worker

    // Cleanup the worker
    return () => {
      worker.terminate();
    };
  }, [reportResponse]);

  return (
    <Box sx={{ pl: "11px", pt: "16px", pr: "30px" }}>
      <Box sx={{ mb: "10px" }}>
        <Breadcrumb items={breadcrumbItems} />
      </Box>
      {!showPreview && (
        <form>
          <Box>
            <APDivider text="Select report variant" margin="10px 0px" />
            <RadioInput
              name="report_variant"
              // controlType="manual"
              control={control}
              options={USPAP_REPORT_VARIANTS}
            />
          </Box>
          <Introduction
            control={control}
            customer_name={reportResponse.request.customer_name}
          />
          <IntendedUse control={control} />
          <RestrictedType control={control} />
          <WorkScope control={control} />
          <ApproachesToValue control={control} />
          <MarketConditionAnalyze control={control} />
          <Sources control={control} />
          <ExtraodinaryAssumptions control={control} />
          <HypotheticalConditions control={control} />
          <LimitingConditions control={control} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "25%",
                gap: 3,
              }}
            >
              <DefaultButton
                text="Download report"
                onClick={handleGeneratePdf}
                disable={Object.keys(dirtyFields).length > 0}
                isLoading={isGenerating || isLoading}
              />
              <DefaultButton
                text="Preview report"
                onClick={handleReportReview}
                variant="outlined"
                disable={Object.keys(dirtyFields).length > 0}
                // isLoading={isUpdating}
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
              <APIconButton
                text="Cancel"
                isRounded={false}
                color={"secondary"}
                icon={<MaterialSymbol icon="delete" />}
                isDisabled={isLoading || isGenerating}
                onClick={() => navigate(-1)}
              />
              <APIconButton
                text="Save"
                isRounded={false}
                variant="text"
                icon={<MaterialSymbol icon="arrow_forward_ios" />}
                isDisabled={isLoading || isGenerating}
                onClick={() => {
                  postReportMutation({ formValues: getValues() });
                }}
              />
            </Box>
          </Box>
        </form>
      )}
      {showPreview && (
        <UspspReportPreview2
          reportResponse={reportResponse}
          report={getValues() as IReportForm}
        />
      )}
      <LoadingAction
        open={showModal}
        title="Compiling Data"
        content="Compliling report data. Please wait..."
      />

      {/* modals */}
      {confirmDialogue}
    </Box>
  );
};

export default ReportsPage;
