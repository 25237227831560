import {
  Drawer,
  IconButton,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
} from "@mui/material";
import React, { useState } from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import AppLogo from "../AppLogo";
import DrawerHeader from "../appbar/DrawerHeader";
import { DRAWER_WIDTH } from "../../Constant";
import NavigationList from "./NavigationList";
import { INavigation } from "./navigationInterfaces";
import { useAuthStore } from "../../features/auth/authStore";
import { MaterialSymbol } from "react-material-symbols";

import LogoutConfirmationModal from "../dialogs/LogoutConfirmationModal";

interface Props {
  open: boolean;
  handleDrawerClose: () => void;
}

const AppNavigation: React.FC<Props> = ({ open, handleDrawerClose }) => {
  const clearLogin = useAuthStore((state) => state.clearLogin);
  const [showLogoutDialogue, setshowLogoutDialogue] = useState<boolean>(false);

  const section1: INavigation[] = [
    {
      icon: "dashboard",
      name: "Dashboard",
      path: "/",
    },
    {
      icon: "perm_media",
      name: "Requests and Assets",
      path: "/requests",
      children: [
        "/requests",
        "/assets",
        "/new-request",
        "/edit-request",
        "/compare",
      ],
    },
    { icon: "history", name: "Appraisal history", path: "/appraisal-history" },
  ];
  const section2: INavigation[] = [
    // { icon: 'note_stack', name: 'Reports and templates', path: '/reports' },
    { icon: "user_attributes", name: "Customers", path: "/customers" },
  ];
  const section3: INavigation[] = [
    {
      icon: "group",
      name: "User management",
      path: "/user-management",
      exclude: ["appraiser", "guest"],
    },
    {
      icon: "domain",
      name: "Organization management",
      path: "/organization-management",
      exclude: ["admin", "appraiser", "guest"],
    },
    { icon: "settings_applications", name: "Settings", path: "/settings" },
  ];
  const section4: INavigation[] = [
    { icon: "auto_delete", name: "Trash", path: "/trash" },
  ];

  const renderDivider = () => <Divider sx={{ margin: "15px 0px" }} />;

  const handleLogout = () => {
    setshowLogoutDialogue(false);
    clearLogin();
  };

  return (
    <Drawer
      sx={{
        width: DRAWER_WIDTH,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: DRAWER_WIDTH,
          boxSizing: "border-box",
        },
      }}
      variant="persistent"
      anchor="left"
      open={open}
    >
      <DrawerHeader>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            height: "100%",
            paddingLeft: 1,
          }}
        >
          <AppLogo pwHeight={50} pwasHeight={30} />
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </Box>
      </DrawerHeader>
      <Divider sx={{ marginBottom: "15px" }} />
      <NavigationList items={section1} />
      {renderDivider()}
      <NavigationList items={section2} />
      {renderDivider()}
      <NavigationList items={section3} />
      {renderDivider()}
      <NavigationList items={section4} />
      <List
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              setshowLogoutDialogue(true);
            }}
          >
            <ListItemIcon>
              <MaterialSymbol icon={"logout"} color="#212B36" size={24} />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItemButton>
        </ListItem>
      </List>

      {/* logout confirmation dialogue */}
      <LogoutConfirmationModal
        open={showLogoutDialogue}
        onCancel={() => setshowLogoutDialogue(false)}
        onLogout={handleLogout}
      />
    </Drawer>
  );
};

export default AppNavigation;
