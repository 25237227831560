import { CustomAxiosRequestConfig, appAPI } from "../../httpClient";

export const createReport = async(requestId:string) => {
    const res = await appAPI.get(`/requests/${requestId}/report`);
    return res.data;
}
export const updateReport = async(requestId:string,data:any) => {
    const res = await appAPI.patch(`/requests/${requestId}/report`,data);
    return res.data;
}
export const generateReport = async(requestId:string,file:Blob)=>{
    const formData = new FormData();
    formData.append('document',file, 'report.pdf');

    return appAPI.post(`/requests/${requestId}/upload_report`,formData,{
        headers:{
            'Content-Type': 'multipart/form-data', 
        }
    } as CustomAxiosRequestConfig);
}