import { IColDef } from "../../components/table/tableInterfaces";
import APTable from "../../components/table/APTable";
import { flattenObject } from "../../utils/jsonObjectNormalize";
import { Box } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { deleteOrganization } from "./organizationQueries";
import { useState } from "react";
import DeleteConfirmationDialog from "../../components/dialogs/DeleteConfirmationDialog";
import { toast } from "react-toastify";
import { handleError } from "../../utils/errorHandler";
import { IPagination, ITableData } from "../../interfaces";

const colDef = [
    {
      field: 'name',
      headerName: 'Organizations',
      type: 'imageText',
      imageField:'logo'
    },
    {
      field: 'owner.name',
      headerName: 'Admin',
      type: 'imageText',
      imageField:'owner.avatar'
    },
    {
      field: 'owner.email',
      headerName: 'Email',
      width: 350,
    },
    // {
    //   field: 'admin_count',
    //   headerName: 'No. of users',
    // },
    {
      field: 'created_at',
      headerName: 'Date added',
      type: 'date',
    },
    {
      field: 'actions',
      headerName: 'Action',
      type: 'action',
    },
  ] as const;

  const colDefWithIds: IColDef[] = colDef.map((col, index) => ({ ...col, id: index + 1 }));

interface Props{
    isLoading?:boolean;
    data:ITableData;
    onRefetch?: ()=>void;
    onRowCheck?:(item:any,details:any)=>void;
    onOpenForm?:(row:any) => void;
    onPageChange?:(val:IPagination)=>void;
}
const OrganizationTable:React.FC<Props> = ({data,isLoading, onRefetch, onRowCheck, onOpenForm, onPageChange}) => {
    const tableData = data.rowData.map((item:any) => flattenObject(item));
    const [selectedRow, setSelectedRow] = useState<any>();
    const [openDeleteDialog, setOpenDeleteDialog]  = useState(false);
    const { isPending: isOrgDeleting, mutate: deleteOrgMution } = useMutation({
        mutationKey: ['delete-org'],
        mutationFn: (orgId: string) => deleteOrganization(orgId),
    });

    const actions = [
        {
          label: 'Edit details',
          action: (row:any)=>{
            if(onOpenForm)onOpenForm(row);
          }
        },
      ];

      const handleCancelDelete = () => {
        setSelectedRow(null);
        setOpenDeleteDialog(false);
    };

    const handleOnDelete = () => {
        if(!selectedRow) return;

        deleteOrgMution(selectedRow.id, {
            onSuccess: () => {
                toast.success('Successfully moved to trash');
                onRefetch?.();
                setOpenDeleteDialog(false);
            },
            onError: handleError
        });
    };
    
    return(
        <Box>
            <APTable columns={colDefWithIds} actions={actions} data={{rowData:tableData, rowCount:data.rowCount}} isLoading={isLoading} onPageChange={onPageChange}/>
            <DeleteConfirmationDialog
                    title="Move to trash"
                    content={'Are you sure you want to move this organization to trash?'}
                    open={openDeleteDialog}
                    onCancel={handleCancelDelete}
                    onDelete={handleOnDelete}
                    isDeleting={isOrgDeleting}
                />
        </Box>
    )
}

export default OrganizationTable;