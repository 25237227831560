import React, { useEffect, useCallback, useState } from "react";
import { useBlocker, useBeforeUnload } from "react-router-dom";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

// ---------------------Confirmation Dialogue box component Code------------------------
interface ConfirmNavigationProps {
  title: string;
  content: string;
  open: boolean;
  onCancel: () => void;
  onContinue: (val: any) => void;
}

// the dialogue box to confirm the navigation process
function ConfirmNavigationDialogue({
  title,
  content,
  open,
  onCancel,
  onContinue,
}: ConfirmNavigationProps) {
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent
        sx={{
          maxWidth: "380px",
        }}
      >
        <DialogContentText id="alert-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "left",
          gap: 1,
        }}
      >
        <Button
          onClick={onContinue}
          variant="text"
          color="secondary"
          sx={{ border: "1px solid red" }}
        >
          DISCARD CHANGES
        </Button>
        <Button onClick={onCancel} color="primary" variant="contained">
          STAY ON PAGE
        </Button>
      </DialogActions>
    </Dialog>
  );
}

// ---------------------- Code for hook to deal with the confirmation-----------------

interface UseUnsavedChangesProps {
  isDirty: boolean;
  /* 
  the onConfirm is not compulsory, you can use it only if you want to run some functions when you confirm the modal
  */
  onConfirm?: () => void;
  /* 
  the onCancel is not compulsory, you can use it only if you want to run some functions when you Cancel and close the modal
  */
  onCancel?: () => void;
}

const useNavigationConfirmationHook = ({
  isDirty,
  onConfirm,
  onCancel,
}: UseUnsavedChangesProps) => {
  const [showModal, setshowModal] = useState<boolean>(false);

  // use the useblocker hook to prevent navigations
  const blocker = useBlocker(() => isDirty);

  // useEffect to handle blocking the navigation when the form is dirty
  useEffect(() => {
    console.log("blocker", blocker);
    console.log("isDirty", isDirty);
    if (isDirty && blocker.state == "blocked") {
      setshowModal(true);
    } else if (blocker.state == "proceeding") {
      (blocker as any).proceed?.();
    } else {
      if (blocker.proceed && blocker?.state !== "blocked") {
        (blocker as any).proceed?.();
      } else {
        (blocker as any).proceed?.();
      }
    }

    return () => {
      blocker.state == "proceeding" && (blocker as any).reset?.();
    };
  }, [isDirty, blocker]);

  // Handle browser events (refresh, close tab)
  useBeforeUnload(
    useCallback(
      (event) => {
        if (isDirty) {
          event.preventDefault();
          return "";
        }
      },
      [isDirty]
    )
  );

  // this is to programtically open the modal, this is in situations where you might be using a modal and want to ensure user confirms before closing the modal
  const triggerOpenNavConfirmModal = () => {
    setshowModal(true);
  };

  // this will close the dialogue and perform any actions you want to perform on cancel
  const handleCancel = () => {
    setshowModal(false);
    onCancel?.();
    (blocker as any).reset?.();
  };

  const handleConfirm = () => {
    console.log("blocker", blocker);
    onConfirm?.();
    setshowModal(false);
    if (blocker.proceed) {
      blocker.proceed?.();
    }
  };

  const confirmDialogue = (
    <>
      <ConfirmNavigationDialogue
        title="Warning: Unsaved Changes"
        content="You have unsaved changes that will be lost if you navigate away from this page."
        open={showModal}
        onCancel={handleCancel}
        onContinue={handleConfirm}
      />
    </>
  );

  return { confirmDialogue, triggerOpenNavConfirmModal };
};

export default useNavigationConfirmationHook;
