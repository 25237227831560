import { Modal, Typography, Box } from "@mui/material";
import FileInput from "../../components/inputs/FileInput";
import { SubmitHandler, useForm } from "react-hook-form";
import { APIconButton } from "../../components/buttons";
import { MaterialSymbol } from "react-material-symbols";
import { LabelCheckboxInput, LabelInput, SelectInput } from "../../components/inputs";
import { isRequired } from "../../utils/validationRules";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useMutation } from "@tanstack/react-query";
import { IOrganizationFormValues } from "./organizationInterfaces";
import { patchOrganization, postOrganization } from "./organizationQueries";
import { toast } from "react-toastify";
import { handleError } from "../../utils/errorHandler";
import { useEffect, useMemo, useState } from "react";

interface Props {
  open: boolean;
  onClose: (event: any) => void;
  users?: any[];
  selectedRow?: any;
  refetchOrganizations?: () => void;
}

const OrganizationForm: React.FC<Props> = ({ open, onClose, users, selectedRow,refetchOrganizations }) => {
  const { control, handleSubmit, reset, formState:{dirtyFields} } = useForm<IOrganizationFormValues>();
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  const formatedUsers = useMemo(
    () => users?.map((user) => ({ id: user.id, value: user.id, label: user.name })),
    [users]
  );
  const handleOnClose = (event:any) => {
    reset({});
    setImageUrl(null);
    onClose(event);
  }

  const { isPending: isLoading, mutate: postOrg } = useMutation({
    mutationKey: ['post-org'],
    mutationFn: postOrganization,
    onSuccess: () => {
      toast.success('Organization created successfully');
      refetchOrganizations?.();
      handleOnClose(null);
    },
    onError: handleError,
  });

  const { isPending: isOrgUpdating, mutate: updateOrg } = useMutation({
    mutationKey: ['update-org'],
    mutationFn: ({ orgId, formValues }: { orgId: string, formValues: IOrganizationFormValues }) =>
      patchOrganization(orgId, formValues),
    onSuccess: () => {
      toast.success('Organization updated successfully');
      refetchOrganizations?.();
      handleOnClose(null);
    },
    onError: handleError,
  });

  const onSubmit: SubmitHandler<IOrganizationFormValues> = (data) => {
    const payload:IOrganizationFormValues = {
      ...data
      // name:data.name,
      // owner:data.owner.value,
      // default_estimate_options:data.default_estimate_options
    }
    
    if (selectedRow) {
      const updatedFields = Object.keys(dirtyFields);
      if(updatedFields.length === 0) return;
      let updatedPayload:any = {};
      (Object.keys(payload) as (keyof IOrganizationFormValues)[]).forEach((key)=>{
        if(updatedFields.includes(key)){
          updatedPayload[key] = payload[key];
        }
      })
      // console.log(updatedPayload)
      // console.log(updatedPayload.file instanceof File)
      updateOrg({ orgId: selectedRow.id, formValues: updatedPayload });
    } else {
      postOrg(payload);
    }
  };

  useEffect(() => {
    if (selectedRow) {
      let default_estimate_options:string[] = [];
      Object.keys(selectedRow).map((key:string)=>{
        if(key.includes('default_estimate_options')){
          default_estimate_options.push(selectedRow[key]);
        }
      })
      reset({
        name: selectedRow.name,
        owner: {
          id: selectedRow['owner.id'],
          value: selectedRow['owner.id'],
          label: selectedRow['owner.name'],
        },
        default_estimate_options
      });
      setImageUrl(selectedRow.logo);
    }
    else{
        reset()
    }
  }, [selectedRow, reset]);

  const modalTitle = selectedRow ? 'Edit organization' : 'Add organization';
  const buttonText = selectedRow ? 'Update' : 'Add organization';

  return (
    <Modal open={open} >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 700,
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 24,
        }}
      >
        <Box sx={{ width: '100%' }}>
          <ValidatorForm onSubmit={handleSubmit(onSubmit)}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                backgroundColor: '#FCFCFC',
                p: '25px',
                borderRadius:'8px 8px 0px 0px'
              }}
            >
              <Typography sx={{ fontWeight: 'bold', fontSize: '24px', color: '#000000' }}>
                {modalTitle}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <Box>
                    <APIconButton
                    text="Cancel"
                    icon={<MaterialSymbol icon="close" />}
                    onClick={handleOnClose}
                    whiteBackground
                    />
                </Box>
                <APIconButton
                text={buttonText}
                icon={<MaterialSymbol icon="add" />}
                type="submit"
                isLoading={isLoading || isOrgUpdating}
                />
              </Box>
            </Box>
            <Box sx={{ p: '25px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Box
                sx={{
                  height: imageUrl?'auto':'150px',
                  width: '150px',
                }}
              >
                <FileInput
                  label="Drag and drop logo"
                  name="file"
                  control={control}
                  isCircle
                  type="image"
                  imageUrl={imageUrl??''}
                />
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', gap: '20px', mt: '20px' }}>
                <LabelInput
                  id="organization-name"
                  label="Organization name*"
                  name="name"
                  control={control}
                  rules={{ validate: { isRequired } }}
                />
                <SelectInput
                  id="owner"
                  label="Select admin*"
                  name="owner"
                  options={formatedUsers??[]}
                  control={control}
                  rules={{ validate: { isRequired } }}
                />
              </Box>
              <Box sx={{width:'100%'}}>
                <Typography sx={{fontWeight:500, fontSize:'18px', mb:'20px'}}>Select default valiation type</Typography>
                <LabelCheckboxInput
                name="default_estimate_options"
                control={control}
                options={[
                  { label: 'FMV', value: 'fmv' },
                  { label: 'FLV', value: 'flv' },
                  { label: 'OLV', value: 'olv' },
                  // { label: 'MOV', value: 'mov' },
                ]}
                rules={{ validate: { isRequired } }}
              />
              </Box>
            </Box>
          </ValidatorForm>
        </Box>
      </Box>
    </Modal>
  );
};

export default OrganizationForm;
