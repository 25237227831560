// src/components/layouts/AuthLayout.tsx
import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import AppLogo from "../../components/AppLogo";
import { useAuthStore } from "./authStore";
import { useGeneralStore } from "../../generaStore";

const AuthLayout: React.FC = () => {
  const location = useLocation();
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const setIsPageTransition = useGeneralStore(
    (state) => state.setIsPageTransition
  );
  const navigate = useNavigate();
  const backgroundImageUrl = (() => {
    switch (location.pathname) {
      case "/login":
        return "/assets/images/login-2.jpg";
      case "/forgot-password":
        return "/assets/images/reset-password.png";
      case "/reset-password":
        return "/assets/images/set-password.png";
      case "/set-password":
        return "/assets/images/set-password.png";
      case "/confirm-email":
        return "/assets/images/reset-password.png";
      default:
        return "/assets/images/login-2.jpg";
    }
  })();

  useEffect(() => {
    if (isLoggedIn) {
      setIsPageTransition(true);
      navigate("/");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <Grid
        item
        xs={false}
        sm={4}
        md={6}
        sx={{
          backgroundImage: `url(${backgroundImageUrl})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid
        item
        xs={12}
        sm={8}
        md={6}
        component={Paper}
        square
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Container
          sx={{
            height: "100%",
            // border: "1px solid yellow",
            width: "76%",
            maxWidth: "100px",
            py: 12,
            paddingX: "0px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div className="">
            <AppLogo pwHeight={70} pwasHeight={35}/>
          </div>

          <Box
            sx={{
              // px: 4,
              // py: 8,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "start",
              flexGrow: "inherit",
              // border: "1px solid red",
            }}
          >
            <Box className="" sx={{ margin: 0, width: "100%",paddingBottom:6 }}>
              <Outlet />
            </Box>
          </Box>
          <Typography
            sx={{ textAlign: "center", width: "100%", fontSize: "12px" }}
          >
            ©Appraisal Service 2024
          </Typography>
        </Container>
      </Grid>
    </Grid>
  );
};

export default AuthLayout;
