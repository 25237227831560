import * as React from 'react';
import { Box, TextField, Typography, useTheme, TextFieldProps } from '@mui/material';
import { styled } from '@mui/system';
import { Control, Controller } from 'react-hook-form';

interface StyledTextValidatorProps {
  background?: 'filled' | 'transparent';
  size?: 'small' | 'medium';
}

// Use forwardRef to ensure the ref is properly forwarded to TextField
const StyledTextValidator = styled(
  React.forwardRef<HTMLDivElement, StyledTextValidatorProps & TextFieldProps>(
    (props, ref) => {
      const { background, size, ...rest } = props;
      return <TextField ref={ref} {...rest} />;
    }
  )
)(({ background, size }) => ({
  '& .MuiInputBase-root': {
    backgroundColor: background === 'transparent' ? '#fff' : '#CED4DA',
    borderRadius: background === 'transparent' ? undefined : '8px',
    border: background === 'transparent' ? '1px solid #CED4DA' : 'none',
    height: size === 'small' ? '42px' : undefined,
    transition: 'border-color 0.3s ease',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
}));

interface LabeledInputProps {
  id?: string;
  label?: string;
  name: string;
  control: Control<any>;
  defaultValue?: string;
  size?: 'small' | 'medium';
  background?: 'filled' | 'transparent';
  disabled?: boolean;
  rules?: object;
}

const LabelInput: React.FC<LabeledInputProps> = ({
  id,
  label,
  name,
  size = 'small',
  background = 'transparent',
  disabled = false,
  control,
  defaultValue = '',
  rules,
}) => {
  const theme = useTheme();
  const primaryColor = theme.palette.primary.main;

  return (
    <Box sx={{ mb: 2, width: '100%' }}>
      {label && (
        <Typography
          component="label"
          htmlFor={id}
          variant="body1"
          sx={{ mb: 1, fontSize: '12px', color: '#4F4F4F' }}
        >
          {label}
        </Typography>
      )}
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        render={({ field, fieldState }) => (
          <StyledTextValidator
            {...field}
            id={id}
            variant="outlined"
            disabled={disabled}
            size={size}
            fullWidth
            background={background}
            error={!!fieldState.error}
            helperText={fieldState.error ? fieldState.error.message : ''}
            sx={{
              '& .Mui-focused': {
                borderColor: primaryColor,
                borderWidth: '1px',
              },
            }}
          />
        )}
      />
    </Box>
  );
};

export default LabelInput;
