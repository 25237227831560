import { Box } from "@mui/material";
import APEnhanceColumnItem from "./APEnhanceColumnItem";
import { IColDef } from "../../table/tableInterfaces";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";

interface Props{
    columns: IColDef[];
    columnConfig?: {columnHide:any, pinnedColumns:{left:string[]; right:string[]}};
    handleOpenPinMenu: (event: React.MouseEvent<HTMLElement, MouseEvent>, field: string) => void;
    toggleColumnVisibility: (field:string)=>void;
}
const APEnhanceColumn:React.FC<Props> = ({columns, columnConfig, handleOpenPinMenu, toggleColumnVisibility}) => {
    return (
        <Box sx={{ p: "25px", maxHeight:"80vh", overflow: "auto" }}>
            <SortableContext items={columns} strategy={verticalListSortingStrategy}>
                {columns.map(col => (
                    <APEnhanceColumnItem
                        key={col.id} 
                        col={col}
                        columnConfig={columnConfig}
                        handleOpenPinMenu={handleOpenPinMenu}
                        toggleColumnVisibility={toggleColumnVisibility}
                    />
                ))}
            </SortableContext>
        </Box>
    )
}
export default APEnhanceColumn;