import { Page, Text, View, Document, StyleSheet, Image, Link } from '@react-pdf/renderer';
import DOMPurify from 'dompurify';
// import { formatDate, getCurrentDate } from '../../../utils/helpers';

interface Props{
    reportResponse: any;
  }
const StandardProfileImageDocument = ({ reportResponse }:Props) => {
  console.log('standard-report: ',reportResponse);
  const defaultValuation =
  reportResponse.request.total_estimates &&
  reportResponse.request.total_estimates.find((es: any) => es.estimate_type === "fmv") ||
  reportResponse.request.total_estimates?.[0];

  const sanitizeHTML = (html: string): string => {
    return DOMPurify.sanitize(html);  // Returns a sanitized string
  };

  const renderHTML = (html: string) => {
    const sanitizedHTML = sanitizeHTML(html);
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = sanitizedHTML;
  
    return Array.from(tempDiv.childNodes).map((node, index) => {
      if (node.nodeName === 'P') {
        // Render <p> as plain text
        return <Text key={index}>{node.textContent}</Text>;
      } else if (node.nodeName === 'STRONG') {
        // Render <strong> as bold text
        return <Text key={index} style={styles.bold}>{node.textContent}</Text>;
      } else if (node.nodeName === 'EM') {
        // Render <em> as italic text
        return <Text key={index} style={{ fontStyle: 'italic' }}>{node.textContent}</Text>;
      } else if (node.nodeName === 'UL') {
        // Render <ul> as a list
        return (
          <View key={index} style={{ marginTop: '10px', display:'flex', flexDirection:'column' }}>
            {Array.from(node.childNodes).map((liNode, liIndex) => {
              if (liNode.nodeName === 'LI') {
                // Render each <li> as a bullet point
                return (
                  <View key={liIndex} style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 10,
                    marginTop: '10px'
                  }}>
                    <Text>•</Text>
                    <Text>{liNode.textContent}</Text>
                  </View>
                );
              }
              return null; // Handle other cases as needed
            })}
          </View>
        );
      }
      return null; // Handle other cases as needed
    });
  };

  const styles = StyleSheet.create({
    pageCenter: {
      padding: 40,
      flexDirection: "column",
      alignItems: "center",
      fontSize:12,
      fontFamily: 'Times-Roman',
      color: '#2c3033',
    },
    sectionCenterAlign:{
      display:'flex',
      flexDirection:'column',
      alignItems:'center'
    },
    contact: {
      textAlign: "center",
      fontSize: 12,
      marginTop: 10,
    },
    link: {
      color: "#0073e6",
      textDecoration: "none",
    },
      page:{
          paddingTop: 35,
          paddingBottom: 65,
          paddingHorizontal: 35,
          flexDirection: "column",
          alignItems: "flex-start",
          fontSize:12,
          color: '#2c3033',
      },
      logo: {
        width: "80%",
        height: "65%",
        marginBottom: 20,
      },
      bold: {
        color:'#000000',
        fontWeight:'heavy'
      },
      flexRow:{
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
      },
      justifyBetween:{
        justifyContent:'space-between'
      },
      width100:{
        width:'100%'
      },
      // footer:{
      //   position: 'absolute',
      //   left: 0,
      //   bottom: 20,
        
      // },
      pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
      },
    });

  return (
      <Document>
        <Page size="A4" style={styles.pageCenter}>
          <Image
          src="/assets/images/logo.png"
          style={styles.logo}
          />
          <View style={[styles.sectionCenterAlign]}>
              <Text>Prepared By:</Text>
              <Text style={styles.bold}>Purple Wave Appraisal Services</Text>
              <Text>825 Levee Dr.</Text>
              <Text>Manhattan, KS 66502</Text>
          </View>
          <View style={[styles.sectionCenterAlign, {marginTop:20}]}>
              <Text style={styles.bold}>{reportResponse?.request.manager?.name}</Text>
              <Text>Appraiser</Text>
          </View>
          <View style={styles.sectionCenterAlign}>
              <Text style={styles.bold}>Purple Wave Appraisal Services</Text>
              <Text>825 Levee Dr., Manhattan, KS 66502</Text>
              <View style={[styles.flexRow, {gap:20}]}>
                  <View style={styles.flexRow}>
                      <Text style={styles.bold}>
                          Toll Free:
                      </Text>
                      <Text>
                          866-608-9283
                      </Text>

                  </View>
                  <Text>|</Text>
                  <View style={styles.flexRow}>
                      <Text style={styles.bold}>
                          Local: 
                      </Text>
                      <Text>
                          785-537-7653
                      </Text>
                  </View>
              </View>
              <Text style={styles.contact}>
                  Email:{" "}
                  <Link style={styles.link} src="mailto:appraisals@purplewave.com">
                  appraisals@purplewave.com
                  </Link>{" "}
                  | Website:{" "}
                  <Link
                  style={styles.link}
                  src="https://www.purplewave.com/appraisals"
                  >
                  www.purplewave.com/appraisals
                  </Link>
              </Text>
          </View>
          <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
              `${pageNumber} / ${totalPages}`
          )} fixed />
      </Page>
          {/* Page 1 */}
      <Page size="A4" style={styles.page}>
            <View style={[styles.flexRow, styles.justifyBetween, styles.width100, {marginBottom:10, alignItems:'flex-start'}]}>
            <Text>Purplewave</Text>
            <Image
            src="/assets/images/logo.png"
            style={{width:'40px', height:'40px'}}
            />
          </View>

          <View style={[styles.flexRow, styles.justifyBetween, styles.width100, {alignItems:'flex-start'}]}>
            <View>
              <Text>EQUIPMENT OWNER</Text>
              <Text style={styles.bold}>{reportResponse.request.customer_name}</Text>
            </View>
            <View style={{display:'flex', flexDirection:'column', alignItems:'flex-end'}}>
              <Text>TOTAL {defaultValuation?.estimate_type.toUpperCase()}</Text>
              <Text style={styles.bold}>$ {defaultValuation?.total_value}</Text>
            </View>
          </View>
          <View style={{width:'100%'}}>
            {
              reportResponse.assets?.map((asset:any)=>(
                <View key={asset.id} style={{
                  marginTop:'40px',
                  paddingTop:5,
                  borderTop:'1px solid #ccc',
                  width:'100%'
                }}>
                  <View style={[styles.flexRow, styles.justifyBetween, styles.width100, {alignItems:'flex-start'}]}  wrap={false}>
                    <View style={[styles.flexRow, {alignItems:'flex-start', gap:5}]}>
                      <Image src={asset.media_urls.length>0?asset.media_urls[0]:'/assets/images/placeholder.png'} style={{width:100, height:100}}/>
                      <View style={{display:'flex', flexDirection:'column', gap:3}}>
                        <Text style={styles.bold}>{asset.category === 'undefined'?'':asset.category??''}</Text>
                        <Text>{asset.location?.split('|').join(' ')}</Text>
                        <Text>Serial</Text>
                        <Text>{asset.serial}</Text>
                        <View style={{marginTop:10}}>
                          <Text>Notes</Text>
                          <Text>{renderHTML(asset.notes)}</Text>
                        </View>
                      </View>
                    </View>
                    <Text style={[styles.bold,{width:'10%'}]}>$ {(asset.estimates.find((es: any) => es.estimate_type === "fmv") || asset.estimates[0]).value}</Text>
                  </View>
                  <View style={[styles.flexRow, styles.width100, {border:'1px solid #000',  gap:30, marginTop:5}]}>
                    <View style={{
                      width:'70px',
                      height:'100%',
                      backgroundColor:'#000',
                      display:'flex',
                      alignItems:'center',
                      justifyContent:'center'
                    }}>
                      <Text style={{color:'#fff'}}>Values</Text>
                    </View>
                    <View style={[styles.flexRow, {gap:80}]}>
                      {
                        asset.estimates.map((estimate:any)=>(
                          <View key={estimate.estimate_type}>
                            <Text style={{fontSize:'10px'}}>{estimate.estimate_type.toUpperCase()}</Text>
                            <Text style={styles.bold}>${estimate.value}</Text>
                          </View>
                        ))
                      }
                    </View>

                  </View>
                </View>
              ))
            } 
          </View>
          
          <Text style={[styles.pageNumber,{}]} render={({ pageNumber, totalPages }) => (
            `${pageNumber} / ${totalPages}`
          )} fixed />
          {/* <View style={{fontSize:'8px', color:'#ccc', position:'absolute', left:0, bottom:10, width:'100%'}}>
            <View style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between', padding:'0px 40px'}}>
              <View>
                <Text>Request ID: {reportResponse.request.id}</Text>
                <Text>Valuation Date: {formatDate(reportResponse.created_at)}</Text>
                <Text>Report Generation Date: {formatDate(getCurrentDate())}</Text>
              </View>
              <Text render={({ pageNumber, totalPages }) => (
                  `Page ${pageNumber}`
              )} fixed />
            </View>
          </View> */}
      </Page>

      </Document>
  )
};

export default StandardProfileImageDocument;