import { Box, Grid, Modal, Typography, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { MaterialSymbol } from "react-material-symbols";
import { APIconButton } from "../../components/buttons";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useForm } from "react-hook-form";
import { LabelInput, SelectInput } from "../../components/inputs";
import { ASSET_HEADERS } from "../../Constant";
import { isRequired } from "../../utils/validationRules";
import { useCategoryStore } from "../../hooks/useCategoryStore";
import { ASSET_STATUS } from "../../data/selectData";

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (val: any) => void;
}

const AssetSearchModal: React.FC<Props> = ({ open, onClose, onSubmit }) => {
  const { categories } = useCategoryStore();
  const { control, handleSubmit, setValue, reset, getValues,setError, watch } = useForm<any>();
  const [searchItems, setSearchItems] = useState<any[]>([]);
  const selectField = watch("select-field");
  const [showSelect, setShowSelect] = useState(true);

  const addFilter = () => {
    setValue('select-field','');
    setShowSelect(true);
  };

  const removeFilter = (label: string) => {
    setSearchItems(prev => prev.filter((item:any)=>item.label!==label))
    if(searchItems.length === 1){
      setValue('select-field','');
      setShowSelect(true);
    }
  };

  const handleClose = () => {
    setSearchItems([]);
    reset();
    onClose();
  };

  const renderFormRow = (label:string, type:"input"|"select") => {
    return (
        <React.Fragment>
          <Grid item xs={12} sx={{height:'100%', display: 'flex',alignItems:"center" }}>
            {
              type==='select' &&
                <SelectInput
                  id={`field-${label}`}
                  name={label}
                  label={label==='assetType'?'ASSET TYPE':label.toUpperCase()}
                  options={label==='assetType'?categories:ASSET_STATUS}
                  rules={{ validate: { isRequired } }}
                  control={control}
                />
            }
            {
              type === 'input' && 
                <LabelInput
                  id={`value-${label}`}
                  label={label === 'sticker' ?'STICKER ID':label.toUpperCase()}
                  name={label}
                  control={control}
                  rules={{ validate: { isRequired } }}
                />
            }
            <Box>
            <IconButton onClick={() => removeFilter(label)}>
              <MaterialSymbol icon="delete" />
            </IconButton>
            </Box>
          </Grid>
        </React.Fragment>
    )
  }

  useEffect(()=>{
    const statusItems = ['status','assetType']
    const val:any = getValues('select-field');
    if(val){
      let data:any = {label:val.value}
      if(statusItems.includes(val.value))data.type = 'select'
      else data.type = 'input'

      setSearchItems(prev => [...prev,data]);
      setShowSelect(false);
    }
    
  },[getValues, selectField])

  return (
    <Modal open={open}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 500,
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 24,
        }}
      >
        <Box sx={{ width: '100%' }}>
          <ValidatorForm onSubmit={handleSubmit(onSubmit)}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                backgroundColor: '#FCFCFC',
                p: '25px',
                borderRadius: '8px 8px 0px 0px',
              }}
            >
              <Typography sx={{ fontWeight: 'bold', fontSize: '24px', color: '#000000' }}>
                Search Plus
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <Box>
                  <APIconButton
                    text="Cancel"
                    icon={<MaterialSymbol icon="close" />}
                    onClick={handleClose}
                    whiteBackground
                  />
                </Box>
                <APIconButton
                  text={'Find'}
                  icon={<MaterialSymbol icon="east" />}
                  type="submit"
                />
              </Box>
            </Box>

            <Box sx={{ width: '100%', p: '25px' }}>
              <Grid container spacing={2}>
              {searchItems.map((item, index) => (
                <React.Fragment key={index}>
                  {renderFormRow(item.label, item.type)}
                </React.Fragment>
              ))}
              </Grid>

              <Grid container spacing={2}>
                {showSelect && <Grid item xs={11}>
                <SelectInput
                  label="Select a field"
                  name="select-field"
                  options={ASSET_HEADERS.filter(
                    header => !searchItems.some(item => item.label === header.value)
                  )}
                  defaultValue=""
                  control={control}
                />
                </Grid>}
                <Grid item xs={6}>
                    <Box sx={{width:'70%'}}>
                    {!showSelect && searchItems.length < ASSET_HEADERS.length &&<APIconButton
                        text="Add Filter"
                        whiteBackground
                        icon={<MaterialSymbol icon="add" />}
                        onClick={addFilter}
                    />}
                    </Box>
                </Grid>
              </Grid>
            </Box>
          </ValidatorForm>
        </Box>
      </Box>
    </Modal>
  );
};

export default AssetSearchModal;
