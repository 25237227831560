import { useEffect, useState } from "react";
import { Box, Button, Grid, Modal, TextField, Typography } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import APDivider from "../../components/APDivider";
import {
  LabelInput,
  LabelCheckboxInput,
  RichTextInput,
  SelectInput,
  TextareaInput,
} from "../../components/inputs";
// import FileInput from '../../components/inputs/FileInput';
// import { EVALUATION_REASONS } from '../../data/selectData';
import ImagePreview from "./ImagePreview";
import { useCategoryStore } from "../../hooks/useCategoryStore";
import { MaterialSymbol } from "react-material-symbols";
import { APIconButton, DefaultButton } from "../../components/buttons";
import JSZip from "jszip";
import { useMutation } from "@tanstack/react-query";
import {
  AssetFormValues,
  IAssetPayload,
  IImage,
  PostAssetVariables,
} from "./assetsInterface";
import { postAsset } from "./assetsQueries";
import { toast } from "react-toastify";
import { handleError } from "../../utils/errorHandler";
import { useRequestStore } from "../requests/requestStore";

import useNavigationConfirmationHook from "../../components/dialogs/useNavigationConfirmationHook";

const AddAssetForm = () => {
  const navigate = useNavigate();
  const selectedRequest = useRequestStore.getState().selectedRequest;
  // console.log(selectedRequest)
  const {
    control,
    formState: { dirtyFields },
    handleSubmit,
    reset,
    setValue,
    watch,
  } = useForm<AssetFormValues>();
  const { categories, fetchCategories } = useCategoryStore();
  const [showAllImages, setShowAllImages] = useState(false);
  const [attachedImages, setAttachedImages] = useState<IImage[]>([]);
  const [openImagePreview, setOpenImagePreview] = useState(false);

  const zipCode = watch("zip");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");

  const [openModal, setOpenModal] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [meterReading, setMeterReading] = useState<any[]>([
    { units: "Hours" },
    { units: "Miles" },
  ]);

  // handle the refresh and navigation away from page incases where the user has a dirty field
  const { confirmDialogue } = useNavigationConfirmationHook({
    isDirty: Object.keys(dirtyFields).length > 0,
  });

  const {
    isPending: isLoading,
    mutate: postAssetMutation,
    isPaused,
  } = useMutation({
    mutationKey: ["postRequestMutation"],
    mutationFn: ({ reqId, formValues }: PostAssetVariables) =>
      postAsset(reqId, formValues),
    onSuccess: (res, variables) => {
      // console.log(variables);
      if (res.status === 204) {
        toast.error("An error occured during asset creation");
      } else {
        toast.success("Asset created successfully");
        if (attachedImages.length > 0)
          toast.success(
            "Media upload in progress. Kindly refresh later to see updates."
          );
        if (variables.actionType === "create") {
          reset();
          navigate(-1);
        } else if (variables.actionType === "createAnother") {
          reset();
          setAttachedImages([]);
        }
      }
    },
    onError: handleError,
  });

  useEffect(() => {
    if (isPaused) {
      toast.warning("It appears you don't have internet connection.");
    }
  }, [isPaused]);

  const onSubmitWithActionType = (actionType: "create" | "createAnother") => {
    return async (data: AssetFormValues) => {
      // console.log('Form Data:', data);
      const {
        vin,
        category,
        sticker,
        serial,
        model,
        make,
        year,
        zip: zipcode,
        city,
        state,
        street,
        unique_id,
        inventory_tag,
        valued,
        notes,
      } = data;
      const meters = meterReading.map((meter: any) => ({
        units: meter.units,
        value: data[meter.units],
      }));
      console.log(meters);
      const payload = {
        vin,
        category: category?.label,
        sticker,
        serial,
        model,
        make,
        year,
        unique_id,
        inventory_tag,
        notes,
        location: `${zipcode}|${state}|${city}|${street}`,
        valued: valued ?? [].length > 0,
      };

      // console.log(payload);
      const zip = new JSZip();

      for (const image of attachedImages) {
        const response = await fetch(image.url);
        const blob = await response.blob();
        zip.file(image.filename, blob);
      }

      const zipBlob = await zip.generateAsync({ type: "blob" });

      const formData = new FormData();

      Object.keys(payload).forEach((key) => {
        const value = payload[key as keyof IAssetPayload];

        formData.append(`asset[${key}]`, value as string);
      });

      formData.append("asset[meters]", JSON.stringify(meters));
      formData.append("asset[media_zip]", zipBlob, "media_zip.zip");
      formData.append("request", selectedRequest.id);

      postAssetMutation({
        reqId: selectedRequest.id,
        formValues: formData,
        actionType,
      });
    };
  };

  const handleAttachCompressedImages = (compressedImages: IImage[]) => {
    setAttachedImages(compressedImages);
    setOpenImagePreview(false);
    setShowAllImages(false);
  };

  const handleFormSubmit = (type: "create" | "createAnother") => {
    handleSubmit(onSubmitWithActionType(type))();
  };

  const handleImagePreviewClose = () => {
    setOpenImagePreview(false);
    setShowAllImages(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleAddField = () => {
    if (inputValue === "") return;
    setMeterReading((prev) => [...prev, { units: inputValue }]);
    setValue(inputValue, null);
    setInputValue("");
    setOpenModal(false);
  };

  useEffect(() => {
    console.log("zicode:", zipCode);
    if (zipCode?.length === 5) {
      fetch(`https://api.zippopotam.us/us/${zipCode}`)
        .then((res) => res.json())
        .then((data) => {
          if (data.places && data.places.length > 0) {
            setCity(data.places[0]["place name"]);
            setState(data.places[0]["state abbreviation"]);
            setValue("city", data.places[0]["place name"]);
            setValue("state", data.places[0]["state abbreviation"]);
          }
        })
        .catch((error) =>
          console.error("Error fetching location data:", error)
        );
    } else {
      setValue("city", "");
      setValue("state", "");
      setValue("street", "");
    }
  }, [zipCode, setValue]);

  const handleViewAllImages = () => {
    setShowAllImages(true);
    setOpenImagePreview(true);
  };
  const displayedImages = attachedImages.slice(0, 10);

  // useEffect(() => {
  //   fetchCategories();
  // }, [fetchCategories]);

  return (
    <Box sx={{ padding: (theme) => theme.spacing(3) }}>
      <Box>
        <form>
          <Box sx={{ display: "flex", gap: 3 }}>
            <Box sx={{ width: "80%" }}>
              <Box>
                <APDivider text="Valuation details" />
                <Box sx={{ display: "flex", width: "50%" }}>
                  <LabelCheckboxInput
                    label="Default valuation types"
                    name="valued"
                    control={control}
                    options={[
                      { label: "List/No asset value", value: "valued" },
                    ]}
                  />
                </Box>
              </Box>

              {/* Equipment details */}
              <Box sx={{ marginTop: "38px" }}>
                <APDivider text="Equipment details" />
                <Box sx={{ display: "flex", width: "100%", gap: 3 }}>
                  <LabelInput
                    id="year"
                    label="Year"
                    name="year"
                    control={control}
                  />
                  <LabelInput
                    id="make"
                    label="Make"
                    name="make"
                    control={control}
                  />
                  <LabelInput
                    id="model"
                    label="Model"
                    name="model"
                    control={control}
                  />
                </Box>
                <Box sx={{ width: "32%" }}>
                  <SelectInput
                    id="category"
                    label="Asset type"
                    name="category"
                    options={categories}
                    control={control}
                  />
                </Box>
              </Box>

              {/* Asset location */}
              <Box sx={{ marginTop: "38px" }}>
                <APDivider text="Asset location" />
                <Box sx={{ display: "flex", width: "100%", gap: 3 }}>
                  <LabelInput
                    id="zip"
                    label="Zip"
                    name="zip"
                    control={control}
                  />
                  <LabelInput
                    id="state"
                    label="State"
                    name="state"
                    disabled
                    control={control}
                    defaultValue={state}
                  />
                  <LabelInput
                    id="city"
                    label="City"
                    name="city"
                    disabled
                    control={control}
                    defaultValue={city}
                  />
                </Box>
                <Box sx={{ width: "32%" }}>
                  <TextareaInput
                    id="street"
                    label="Street"
                    name="street"
                    control={control}
                  />
                </Box>
              </Box>

              <Box sx={{ marginTop: "38px" }}>
                <APDivider text="Identification numbers" />
                <Box
                  sx={{
                    display: "grid",
                    width: "100%",
                    gap: 3,
                    gridTemplateColumns: "repeat(3, 1fr)", // 3 equal columns
                  }}
                >
                  <LabelInput
                    id="serial"
                    label="Serial number"
                    name="serial"
                    control={control}
                  />
                  <LabelInput
                    id="vin"
                    label="VIN"
                    name="vin"
                    control={control}
                  />
                  <LabelInput
                    id="sticker"
                    label="Sticker"
                    name="sticker"
                    control={control}
                  />
                  <LabelInput
                    id="unique_id"
                    label="Unique Id"
                    name="unique_id"
                    control={control}
                  />
                  <LabelInput
                    id="inventory_tag"
                    label="Inventory tag"
                    name="inventory_tag"
                    control={control}
                  />
                </Box>
              </Box>

              <Box sx={{ marginTop: "38px" }}>
                <APDivider text="Service meter reading" />
                <Box
                  sx={{
                    display: "grid",
                    width: "100%",
                    gap: 3,
                    gridTemplateColumns: "repeat(3, 1fr)", // 3 equal columns
                  }}
                >
                  {meterReading.map((reading: any) => (
                    <LabelInput
                      key={reading.units}
                      id={reading.units}
                      label={reading.units}
                      name={reading.units}
                      control={control}
                    />
                  ))}
                  <Box sx={{ width: "100%", mt: "22px" }}>
                    <Button
                      variant="text"
                      sx={{
                        backgroundColor: "#F2F2F2",
                        color: "#19161D",
                        borderRadius: "50px",
                        padding: "10px 16px",
                        fontSize: "14px",
                      }}
                      endIcon={<MaterialSymbol icon="add" />}
                      onClick={handleOpenModal}
                    >
                      Add field
                    </Button>
                  </Box>
                </Box>
              </Box>

              <Box sx={{ marginTop: "38px" }}>
                <APDivider text="Notes" />
                <Box sx={{ width: "100%", paddingTop: "15px" }}>
                  <RichTextInput id="notes" name="notes" control={control} />
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "25%",
                    gap: 3,
                  }}
                >
                  <DefaultButton
                    text="Add asset"
                    onClick={() => handleFormSubmit("create")}
                    isLoading={isLoading && !isPaused}
                  />
                  <DefaultButton
                    text="Cancel"
                    onClick={() => navigate(-1)}
                    variant="outlined"
                    isLoading={isLoading && !isPaused}
                  />
                </Box>

                <Button
                  variant="text"
                  color="primary"
                  endIcon={<ArrowForwardIosIcon />}
                  onClick={() => handleFormSubmit("createAnother")}
                  disabled={isLoading && !isPaused}
                >
                  Save and create another
                </Button>
              </Box>
            </Box>

            <Box sx={{ width: "20%" }}>
              <Box sx={{ height: "120px", mt: "12px" }}>
                <ImagePreview
                  onAttachCompressedImages={handleAttachCompressedImages}
                  onOpenModal={openImagePreview}
                  onModalClose={handleImagePreviewClose}
                />
                <Typography sx={{ fontSize: "10px", color: "#53575A" }}>
                  PNG, JPEG
                </Typography>
              </Box>
              <Box sx={{ mt: "47px" }}>
                <Grid container rowSpacing={1} columnSpacing={0.5}>
                  {displayedImages.slice(0, 10).map((image) => (
                    <Grid item xs={6} key={image.url}>
                      <Box sx={{ width: "151px", height: "151px" }}>
                        <img
                          loading="lazy"
                          src={image.url}
                          alt={image.filename}
                          width={"100%"}
                          height={"100%"}
                        />
                      </Box>
                    </Grid>
                  ))}
                </Grid>
                {attachedImages.length > 10 && !showAllImages && (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Button onClick={handleViewAllImages} variant="text">
                      View all images
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </form>
      </Box>

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography sx={{ mb: 1, fontSize: "12px", color: "#4F4F4F" }}>
            Enter field name
          </Typography>
          <TextField
            label="Field Name"
            size="small"
            fullWidth
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
          <Box sx={{ display: "flex", alignItems: "center", gap: 2, mt: 3 }}>
            <APIconButton
              text="Add"
              isRounded
              icon={<MaterialSymbol icon="add" />}
              onClick={handleAddField}
            />
            <APIconButton
              text="Cancel"
              isRounded
              icon={<MaterialSymbol icon="close" />}
              onClick={handleCloseModal}
              extrastyles={{
                backgroundColor: "#F2F2F2",
                color: "#19161D",
                "&:hover": {
                  backgroundColor: "#f0f0f0",
                },
              }}
            />
          </Box>
        </Box>
      </Modal>
      {/* dialogue box to handle navigation confirmation */}
      {confirmDialogue}
    </Box>
  );
};

export default AddAssetForm;
