import { Box, Button,CircularProgress, SxProps } from "@mui/material";
import { ReactNode } from "react";

interface APIconButtonProps {
    text: string;
    icon: ReactNode;
    onClick?: (event:any) => void;
    whiteBackground?: boolean;
    color?: any;
    isRounded?:boolean;
    type?:"button" | "submit" | "reset";
    variant?:"text" | "outlined" | "contained";
    iconPosition?: "start" | "end";
    isLoading?:boolean;
    isDisabled?:boolean;
    extrastyles?:SxProps;
}

const APIconButton: React.FC<APIconButtonProps> = ({ text, icon, onClick, whiteBackground = false, color='primary',variant='contained', isRounded='false', iconPosition='end', type, isLoading=false, isDisabled=false, extrastyles={}}) => {
    return (
        <Box>
            <Button
                variant={variant}
                onClick={onClick}
                startIcon={iconPosition === "start" ? isLoading ? <CircularProgress color="inherit" size={20} sx={{marginRight:1}} />:icon:null}
                endIcon={iconPosition === "end" ? isLoading ? <CircularProgress color="inherit" size={20} sx={{marginRight:1}} />:icon:null}
                color={color}
                type={type}
                disabled={isLoading||isDisabled}
                sx={{
                    fontSize:'14px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '10px',
                    borderRadius: isRounded?'40px':undefined,
                    height:'45px',
                    width:'100%',
                    border: (theme)=>whiteBackground ? `1px solid #EBEBEB` : undefined,
                    backgroundColor: whiteBackground ? '#fff' : undefined,
                    color: whiteBackground ? '#000' : undefined,
                    '&:hover': {
                        backgroundColor: whiteBackground ? '#f0f0f0' : undefined,
                    },
                    boxShadow:0,
                    ...extrastyles
                }}
            >
                {text}
            </Button>
        </Box>
    );
}

export default APIconButton;
