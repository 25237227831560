import { appAPI } from "../../httpClient";
import { IAsset } from "./assetsInterface";

export const getAssets = async (params: any) => {
  const res = await appAPI.get(`/assets`, { params });
  return {
    data: res.data.data.values,
    meta: res.data.data.meta
  }
};

export const getAssetById = async (requestId:string,assetId: string) => {
  const res = await appAPI.get(`/assets/${assetId}`);
  return res.data;
};

export const postAsset = async (reqId:string,assetData: any) => {
  return appAPI.post<IAsset>(`/assets`, assetData);
};

export const updateAsset = async (requestId:string, assetId: string, assetData: any) => {
  return appAPI.put(`/assets/${assetId}`, assetData);
};

export const deleteAsset = async (assetId: string) => {
  return appAPI.delete(`/assets/${assetId}`);
};

export const postAssetEstimate = async (requestId:string,assetId: string, estimateData:any) => {
  return appAPI.post(`/estimates`,estimateData);
}

export const getAssetEstimateByAssetId = async (requestId:string,assetId: string) => {
  if(!assetId){
    return [];
  }
  // console.log('in request: ', assetId)
  const response = await appAPI.get(`/estimates`,{params:{'filters[asset]':assetId}});
  return response.data.data.values;
};

export const bulkDeleteAsset = async (payload: any) => {
  return appAPI.post("/assets/bulk_delete", payload);
};

export const deleteImage = async (id: string) => {
  return appAPI.delete(`/media/${id}`);
};

