import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { APIconButton, DefaultButton } from "../../buttons";
import { MaterialSymbol } from "react-material-symbols";
import { IColDef, IColumnConfig } from "../../table/tableInterfaces";
import { Menu, MenuItem } from "@mui/material";
import { DndContext, DragEndEvent, closestCenter} from "@dnd-kit/core";
import APEnhanceColumn from "./APEnhanceColumn";

interface Props {
  open: boolean;
  columns: IColDef[];
  columnConfig?: IColumnConfig;
  onClose?: () => void;
  onSaveColumsConfigChanges?: ()=>void;
  onColumnVisibilityChange?:(field:string)=>void;
  onColumnPiningChange?:(field: string, position: 'left' | 'right' | 'unpin') => void;
  onColumnOrderChanged?:(columns: IColDef[])=>void;
  onResetToDefault?:()=>void;
}

const ColumnEnhancementModal: React.FC<Props> = ({ open, columns, columnConfig, onClose, onSaveColumsConfigChanges, onColumnVisibilityChange, onColumnPiningChange, onColumnOrderChanged, onResetToDefault }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedColumn, setSelectedColumn] = useState<string | null>(null);
  const [defaultCols, setDefaultCols] = useState<IColDef[]>([]);
 
  const toggleColumnVisibility = (field: string) => onColumnVisibilityChange?onColumnVisibilityChange(field):null;

  const handleOpenPinMenu = (event: React.MouseEvent<HTMLElement, MouseEvent>, field: string) => {
    setAnchorEl(event.currentTarget);
    setSelectedColumn(field);
  };

  const handlePinColumn = (position: "left" | "right" | "unpin") => {
    if(onColumnPiningChange && selectedColumn)onColumnPiningChange(selectedColumn, position);
    setAnchorEl(null);
  };

  const getColumnPosition = (id:any) => defaultCols.findIndex((col)=> col.id === id)
  const handleOnDragEnd = (event: DragEndEvent) => {
    const {active, over} = event;
    if (!over?.id || active.id === over.id) return;

    const oldIndex = getColumnPosition(active.id);
    const newIndex = getColumnPosition(over.id);

    if (oldIndex === -1 || newIndex === -1) return;

    const updatedColumns = [...defaultCols];
    const [movedColumn] = updatedColumns.splice(oldIndex, 1);
    updatedColumns.splice(newIndex, 0, movedColumn);

    setDefaultCols(updatedColumns);

    if(onColumnOrderChanged) onColumnOrderChanged(updatedColumns);

  }

  useEffect(()=>{
    setDefaultCols(columns)
  },[columns])

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: 2,
        }}
      >
        {/* Header */}
        <Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", p: "25px", bgcolor: "#F9FBFE", borderRadius:'8px 8px 0px 0px' }}>
            <Typography>Edit Columns</Typography>
            <Box sx={{ display: "flex", gap: "8px" }}>
              <APIconButton text="Cancel" whiteBackground icon={<MaterialSymbol icon="close" />} onClick={onClose} />
              <APIconButton text="Save changes" icon={<MaterialSymbol icon="check" />} onClick={onSaveColumsConfigChanges} />
            </Box>
          </Box>
          <Box sx={{width:'200px'}}>
            <DefaultButton text="Reset to default layout" variant="text" onClick={onResetToDefault} disable={columnConfig?.isDefault}/>
          </Box>

        </Box>

        <DndContext collisionDetection={closestCenter} onDragEnd={handleOnDragEnd}>
          <APEnhanceColumn 
            columns={defaultCols}
            columnConfig={columnConfig}
            handleOpenPinMenu={handleOpenPinMenu}
            toggleColumnVisibility={toggleColumnVisibility}
          />
        </DndContext>
        
        {/* Pinning Menu */}
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
          <MenuItem onClick={() => handlePinColumn("left")}>Pin Left</MenuItem>
          <MenuItem onClick={() => handlePinColumn("right")}>Pin Right</MenuItem>
          <MenuItem onClick={() => handlePinColumn("unpin")}>Unpin</MenuItem>
        </Menu>
      </Box>
    </Modal>
  );
};

export default ColumnEnhancementModal;
