import React, { useEffect } from "react";
import { useAuthStore } from "./authStore";
import { addCustomValidationRules, removeCustomValidationRules } from "../../utils/validators";
import { Box, Typography, Divider, Link } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { ValidatorForm } from "react-material-ui-form-validator";
import { toast } from "react-toastify";
// import CustomSwitch from "../../components/CustomSwitch";
import { LabelInput2, PasswordInput } from "../../components/inputs";
import { ILoginForm } from "./authInterfaces";
import { login } from "./authQueries";
import { DefaultButton } from "../../components/buttons";
import { handleError } from "../../utils/errorHandler";
import { Height } from "@mui/icons-material";

const LoginForm:React.FC = () => {
  const storeLogin = useAuthStore(state => state.storeLogin);
  const controller = React.useMemo(() => new AbortController(), []);
  const [formData, setFormData] = React.useState<ILoginForm>({
    email: '',
    password: '',
  });
  // const [rememberMe, setRememberMe] = React.useState(false);

  React.useEffect(() => {
    addCustomValidationRules();
    return () => {
      removeCustomValidationRules();
    };
  }, []);

  const {isPending: isLoading, mutate: loginMutation, isPaused} = useMutation({
    mutationKey: ['login'],
    // retry:2,
    mutationFn: (formValues: ILoginForm) => {
      // If the request gets paused, cancel it
      if (isPaused) {
        controller.abort();
      }
      return login(formValues, controller.signal);
    }
  });

  useEffect(()=>{
    if(isPaused){
      toast.warning('It appears you don\'t have internet connection.')
    }
  },[isPaused])
  
  const handleSubmit = () => {
    const payload: ILoginForm = {
      email: formData.email.trim(),
      password: formData.password.trim()
    }

    loginMutation(payload,{
      onSuccess(response){
        // console.log('on login success called: ', response);
        storeLogin(response);
        toast.success('Login successful',{
          autoClose:200,
        });
        setFormData({email:'',password:''});
      },
      onError(error: any){
        console.log(error);
        
        handleError(error);
      }
    })
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { name, value } = event.target;
    if(name === 'email') value = value.toLocaleLowerCase();
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // const handleRememberMeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setRememberMe(event.target.checked);
  // };
    return (
        <Box sx={{width:'100%', height:'100%'}}>
            <Typography component="h1" variant="h6" sx={{ mb: 2, fontWeight:'bold' }}>
              Nice to see you again!
            </Typography>
            <ValidatorForm onSubmit={handleSubmit}>
              <LabelInput2
                id='email'
                label="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                validators={['required', 'isEmail']}
                errorMessages={['Email is required', 'Email is not valid']}
                sx={{Height:'10px'}}
              />
              <PasswordInput
                id='password'
                label="Password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                validators={[
                  'required',
                ]}
                errorMessages={[
                  'Password is required'
                ]}
              />
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end', mb: 2, mt: 4 }}>
                {/* <FormControlLabel
                  control={
                    <Box sx={{ ml: '12px', mr: 1 }}>
                      <CustomSwitch
                        checked={rememberMe}
                        onChange={handleRememberMeChange}
                      />
                    </Box>
                  }
                  label="Remember me"
                /> */}
                <Link href="/forgot-password" variant="body2" sx={{ textDecoration: 'none' }}>
                  Forgot password?
                </Link>
              </Box>
              <DefaultButton 
                text="Sign In"
                type="submit"
                isLoading={isLoading&&!isPaused}
              />
            </ValidatorForm>
            {/* <Divider sx={{ my: 1 }} /> */}
            {/* <Button
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, height: '45px', backgroundColor: '#333333' }}
            >
              <img
                src={'/assets/images/google-icon.png'}
                width={20}
                height={20}
                alt="google-icon"
                style={{ marginRight: '4px' }}
              />
              Or sign in with Google
            </Button> */}
        </Box>
    )
}
export default LoginForm;