import { Box, Button, IconButton } from "@mui/material";
import APDivider from "../../components/APDivider";
import { FileInput, LabelInput, PasswordInput2, RichTextInput } from "../../components/inputs";
import { useForm } from "react-hook-form";
import { APIconButton } from "../../components/buttons";
import { MaterialSymbol } from "react-material-symbols";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { useEffect, useState } from "react";
import { isRequired } from "../../utils/validationRules";
import { useAuthStore } from "../auth/authStore";
import { useNavigate } from "react-router-dom";
// import { resetPassword } from "../auth/authQueries";
import { DEFAULT_PROFILE } from "../../Constant";
import { useMutation } from "@tanstack/react-query";
// import { IUserForm } from "../user-management/userInterfaces";
import { updatePassword } from "./profileQueries";
import { toast } from "react-toastify";
import { handleError } from "../../utils/errorHandler";
import { updateUser } from "../user-management/userQueries";

const ProfilePage = () => {
    const navigate = useNavigate();
  const {authUser, clearLogin, updateAuthUser} = useAuthStore();
  const { control, handleSubmit, reset, formState:{dirtyFields} } = useForm();
  const { control: passwordControl, handleSubmit: handlePasswordSubmit} = useForm();

  const [imagePreview, setImagePreview] = useState<string>(`/assets/images/${DEFAULT_PROFILE}`);
  const [imageFile, setImageFile] = useState<File | null>(null);
  // const [signatureImageUrl, setSignatureImageUrl] = useState<File | null>(null);

  const { isPending: isUpdatingPassword, mutate: updatingPasswordMutation } = useMutation({
    mutationKey: ['update-password',authUser?.id],
    mutationFn: ({ userId, formValues }: { userId: string, formValues: any }) =>
      updatePassword(userId,formValues),
    onSuccess: () => {
      toast.success('Password updated successfully');
      clearLogin();
    },
    onError: handleError,
});

const { isPending: isUpdatingProfile, mutate: updatingProfileMutation } = useMutation({
  mutationKey: ['update-profile',authUser?.id],
  mutationFn: ({ userId, formValues, headers }: { userId: string, formValues: any, headers:any }) =>
    updateUser(userId,formValues,headers),
  onSuccess: (response:any ) => {
    updateAuthUser(response)
    toast.success('Profile updated successfully');
  },
  onError: handleError,
});

  const onSubmitProfile = (data: any) => {
    // console.log(data);
    
    if(Object.keys(dirtyFields).length === 0 && imageFile===null) return;

    const formKeys = Object.keys(dirtyFields);

    // Handle profile update
    const formData = new FormData();
    
    if(formKeys.includes('name')){
      formData.append("user[name]", data.name);
    }
    if(formKeys.includes('qualifications')){
      formData.append("user[bio]", data.qualifications);
    }

    if (data.file instanceof File) {
      formData.append('user[signature_image]', data.file);
  }
    
    if (imageFile !== null) {
      formData.append("user[image]", imageFile); // Append image file
    }
    updatingProfileMutation({userId: authUser?.id!, formValues:formData, headers:{
      'Content-Type': 'multipart/form-data'
    }})
      
  };

  const onSubmitPassword = (data: any) => {
    // Handle password update
    // const payload = {
    //   current_password:data.currentPassword,
    //   new_password:data.confirmPassword
    // }
    console.log(data);
    updatingPasswordMutation({userId:authUser?.id!,formValues:{...data}})
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string); // Update image preview
      };
      reader.readAsDataURL(file); // Read the file as a data URL
      setImageFile(file); // Store the image file in state
    }
  };

  const handlePasswordFromSubmit = () => {
    handlePasswordSubmit(onSubmitPassword)()
  }
  const handleProfileFromSubmit = () => {
    handleSubmit(onSubmitProfile)();
  }

  const handleCancel = () => {
    navigate(-1); // Navigate back to the previous page
  };

  useEffect(()=>{
    reset({
      name:authUser?.name,
      email:authUser?.email,
      role:authUser?.role,
      qualifications:authUser?.bio
    });
    setImagePreview(authUser?.avatar??`/assets/images/${DEFAULT_PROFILE}`)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (
    <Box sx={{ padding: (theme) => theme.spacing(3) }}>
      {/* Main form for profile update */}
      <form>
        {/* Form contents */}
        <Box sx={{ mb: "60px", position: "relative", width: "150px", height: "150px" }}>
          <Box sx={{ width: "100%", height: "100%", borderRadius: "100%", overflow: "hidden" }}>
            <img
              src={imagePreview}
              alt="Profile"
              style={{ width: "100%", height: "100%", borderRadius: "100%", objectFit: "cover" }}
            />
          </Box>
          {/* Icon to update image */}
          <IconButton
            sx={{
              position: "absolute",
              bottom: 0,
              right: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              color: "white",
              "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.7)" },
            }}
            component="label"
          >
            <CameraAltIcon />
            {/* Hidden input to upload new image */}
            <input type="file" hidden onChange={handleImageChange} accept="image/*" />
          </IconButton>
        </Box>

        <Box>
          <APDivider text="Info" />
          <Box sx={{ display: "flex", width: "50%", gap: 3, paddingTop: "15px" }}>
            <LabelInput
              id="name"
              label="Full name"
              name="name"
              control={control}
            />
            <LabelInput
              id="email"
              label="Email address"
              name="email"
              disabled
              control={control}
            />
            <LabelInput
              id="role"
              label="Role"
              name="role"
              disabled
              control={control}
            />
          </Box>
        </Box>
        {authUser?.role === 'appraiser' && <Box>
          <APDivider text="Certifications and signature" margin="40px 0px"/>
          <RichTextInput id="qualifications" name="qualifications" control={control} />
          <Box sx={{backgroundColor:'#fff', my:'20px', height:'180px', width:'180px'}}>
            <FileInput
              label="Drag and drop signature here"
              name="file"
              control={control}
              isCircle
              type="image"
              imageUrl={authUser?.signature}
            />
          </Box>
        </Box>}
      </form>

      {/* Separate form for updating the password */}
      <form>
        <Box sx={{ mt: 5 }}>
          <APDivider text="Security" />
          <Box sx={{ display: "flex", width: "75.7%", gap: 3, paddingTop: "15px" }}>
            <PasswordInput2
              id="current_password"
              label="Current password"
              name="current_password"
              control={passwordControl}
              rules={{ validate: { isRequired } }}
            />
            <PasswordInput2 
                id="new_password" 
                label="New password" 
                name="new_password" 
                control={passwordControl}
                rules={{ validate: { isRequired } }}
            />
            <PasswordInput2
              id="password_confirmation"
              label="Confirm password"
              name="password_confirmation"
              control={passwordControl}
              rules={{ validate: { isRequired } }}
            />
          </Box>
          <Box sx={{ width: "200px", marginTop: "20px" }}>
            <APIconButton 
                text={"Update Password"} 
                icon={<MaterialSymbol icon="check" />} 
                onClick={handlePasswordFromSubmit}
                isLoading={isUpdatingPassword}
            />
          </Box>
        </Box>
      </form>

      <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: "40px", width: "18%" }}>
        <Box sx={{ width: "60%" }}>
          <Button 
            variant="contained" 
            onClick={handleProfileFromSubmit} 
            color="primary" 
            fullWidth
            disabled={isUpdatingProfile}
            >
            Update
          </Button>
        </Box>
        <Button variant="outlined" sx={{ ml: 1 }} onClick={handleCancel} disabled={isUpdatingProfile}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default ProfilePage;
