import { Box, Button, CircularProgress, Grid, Modal, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { MaterialSymbol } from "react-material-symbols";
import { IImage } from "./assetsInterface";
import { APReactPanZoom } from "../../components/APReactPanZoom";

interface Props {
  onOpen: boolean;
  isProcessing?: boolean;
  images?: IImage[];
  showDelete?: boolean;
  onClose?: () => void;
  onDelete?: (index: number) => void;
  onAttach?: () => void;
}

const ImagePreviewModal: React.FC<Props> = ({
  onOpen,
  images = [],
  isProcessing,
  showDelete,
  onClose,
  onDelete,
  onAttach,
}) => {
  const imagesPerPage = 8;
  const [currentIndex, setCurrentIndex] = useState(1);
  const [mainImage, setMainImage] = useState<string>(images[0]?.url);
  const [currentPage, setCurrentPage] = useState(0);

  const totalImages = images.length;

  useEffect(() => {
    if (!isProcessing && images.length > 0) {
      setMainImage(images[0].url);
    }
  }, [images, isProcessing]);

  return (
    <Modal
      open={onOpen}
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }} // Center modal
    >
      <Box
        sx={{
          width: "50%",
          backgroundColor: "white",
          borderRadius: "8px",
          height: "70%",
          overflow: "hidden",
        }}
      >
        {isProcessing && (
          <Box sx={{ width: "100%", textAlign: "center", mt: 2 }}>
            <CircularProgress />
            <Typography variant="body2" color="textSecondary">
              Processing images...
            </Typography>
          </Box>
        )}
        {!isProcessing && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              width:'100%'
            }}
          >
            <Box
              sx={{
                width: "70%",
                height: "95%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                overflow: "hidden",
              }}
            >
              <APReactPanZoom image={mainImage} alt="Main Display"/>
            </Box>

            <Box
              sx={{
                width: "30%",
                height: "100%",
                p: "8px 10px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "start",
                  }}
                >
                  <Typography>{images.length} images</Typography>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <Box onClick={onAttach} sx={{ cursor: "pointer" }}>
                      <MaterialSymbol icon="check" size={20} />
                    </Box>
                    <Box onClick={onClose} sx={{ cursor: "pointer" }}>
                      <MaterialSymbol icon="close" size={20} />
                    </Box>
                  </Box>
                </Box>

                <Grid container rowSpacing={1} columnSpacing={-0.5} sx={{ my: 2 }}>
                  {images
                    .slice(currentPage * imagesPerPage, (currentPage + 1) * imagesPerPage)
                    .map((image, index) => (
                      <Grid item xs={6} key={image.filename}>
                        <Box sx={{ position: "relative" }}>
                          <Button
                            sx={{
                              border:
                                mainImage === image.url
                                  ? "2px solid blue"
                                  : "none",
                              height: "120px",
                              width: "120px",
                              padding: 0,
                              borderRadius: 0,
                            }}
                            onClick={() => {
                              setMainImage(image.url);
                              setCurrentIndex(
                                currentPage > 0
                                  ? index + imagesPerPage * currentPage + 1
                                  : index + 1
                              );
                            }}
                          >
                            <img
                              src={image.url}
                              alt={`Thumbnail ${index}`}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                cursor: "pointer",
                              }}
                            />
                          </Button>
                          {/* {showDelete && ( */}
                            <Box
                              sx={{
                                position: "absolute",
                                top: "10px",
                                right: "20px",
                                cursor: "pointer",
                                backgroundColor: "white",
                                borderRadius: "100%",
                                width: "20px",
                                height: "20px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                              onClick={() => {
                                if (onDelete) onDelete(index);
                              }}
                            >
                              <MaterialSymbol icon="delete" size={16} color="primary" />
                            </Box>
                          {/* )} */}
                        </Box>
                      </Grid>
                    ))}
                </Grid>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mt: 2,
                }}
              >
                <Button
                  disabled={currentPage === 0}
                  onClick={() => setCurrentPage((prev) => prev - 1)}
                >
                  Previous
                </Button>
                <Typography variant="body1" sx={{ mx: 2 }}>
                  {currentIndex}/{images.length}
                </Typography>
                <Button
                  disabled={(currentPage + 1) * imagesPerPage >= totalImages}
                  onClick={() => setCurrentPage((prev) => prev + 1)}
                >
                  Next
                </Button>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default ImagePreviewModal;
