import { Box, IconButton, Menu, MenuItem} from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState, MouseEvent } from "react";
import RequestTable from "./RequestTable";
import APTableFilter from "../../components/table/APTableFilter";
import DeleteConfirmationDialog from "../../components/dialogs/DeleteConfirmationDialog";
import { APIconButton } from "../../components/buttons";
import { MaterialSymbol } from "react-material-symbols";
import { useFetchRequests } from "../../hooks/useFetchRequests";
import { useAuthStore } from "../auth/authStore";
import { useGeneralStore } from "../../generaStore";
import { flattenObject } from "../../utils/jsonObjectNormalize";
import { STATUS_FILTERS, STATUS_FILTERS_MAP } from "../../Constant";
import { SearchInput } from "../../components/inputs";
import AddIcon from '@mui/icons-material/Add';
import { IPagination, StatusFilterItem } from "../../interfaces";
import useDeleteRequest from "../../hooks/useDeleteRequest";
import { useMutation } from "@tanstack/react-query";
import { bulkDeleteRequest } from "./requestQueries";
import { toast } from "react-toastify";
import { handleError } from "../../utils/errorHandler";
import { Config, transformData } from "../../utils/transformData";

const userFilters = ['All', 'Assigned to me', 'Shared'];

const config: Config[] =[
  {key:'id', field:'id'},
  {key:'author_name',field:'author.name'},
  {key:'customer_name', field:'customer_name'},
  {key:'assets_count', field:'assets_count'},
  {key:'media_count', field:'media_count'},
  {key:'created_at', field:'created_at'},
  {key:'updated_at', field:'updated_at'},
  {key:'status', field:'status'},
  {key:'collaborators', field:'collaborators'},
  {key:'manager_email', field:'manager.email'}
];

const preserve = ['collaborators'];

const RequestsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState<any[]>([]);
  const [reqData, setReqData] = useState<any[]>([]);  // Added reqData state
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [contentText, setContentText] = useState("");
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [params, setParams] = useState({limit:10, page:1});
  const [selectedOrg,setSelectedOrg] = useState();

  const authUser = useAuthStore((state) => state.authUser);
  const setIsPageTransition = useGeneralStore((state) => state.setIsPageTransition);
  const { isPending: requestLoading, data: requestResponse, isRefetching, refetch } = useFetchRequests(params);
  const { mutate: deleteRequestMutation, isPending: isRequestDeleting } = useDeleteRequest({
    refetch,
    onSuccess: () => setOpenDeleteDialog(false),
  });

  const { isPending: isBulkDeleting, mutate: bulkDeleteRequestsMutation } = useMutation({
    mutationKey: ['bulkDeleteRequestMutation'],
    mutationFn: (formValues: any) => bulkDeleteRequest(formValues),
    onSuccess: (res,variables) => {
      console.log(variables);
      if(res.status === 204){
        toast.error('An error occured during request creation');
      }
      else{
        toast.success('Requests moved to trash successfully');
        refetch();
        setOpenDeleteDialog(false);
      }
    },
    onError: handleError,
  });

  const handleFilterClicked = (filter: string) => {
        if(filter === 'all'){
          setParams((prev:any) =>{
            delete prev.status;
            return { ...prev }}
          );
        }
        else{
          setParams((prev) => ({ ...prev, 'filters[status]':  STATUS_FILTERS_MAP[filter]}));
        }
  }

  const handleOwnerClicked = (filter: string) => {
    const ownerData = reqData.filter(
      (item) => item["manager_email"] === authUser?.email
    );

    const sharedData = reqData.filter((item) => 
      item.collaborators?.some((collaborator:any) => collaborator.email === authUser?.email)
    );
    
    setData(filter === 'All'?reqData:filter==="Shared" ? sharedData : ownerData);
  };

  const handleDeleteRequest = () => {
    if (selectedRowIds.length >= 2) {
      bulkDeleteRequestsMutation({requests:selectedRowIds});
    } else {
      deleteRequestMutation(selectedRow?.id);
    }
  };

  const handleMultipleDelete = () => {
    setContentText("Are you sure you want to move these requests to trash?");
    setOpenDeleteDialog(true);
  }

  const handleSingleDelete = (row: any) => {
    // Set selected row and open the delete confirmation dialog
    setSelectedRow(row);
    setContentText("Are you sure you want to move this request to trash?");
    setOpenDeleteDialog(true);
  };

  const handleRowSelection = (selectedIds: string[]) => setSelectedRowIds(selectedIds);

  const handleCancelDelete = () => {
    setSelectedRow(null);
    setOpenDeleteDialog(false);
  };

  const handleSearchSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (searchQuery.trim() !== '') {
      const searchPayload ={
        'search[customer_name]':searchQuery,
        'search[author.name]':searchQuery
      };
      setParams((prev) => ({ ...prev, ...searchPayload }));
    } 
  };

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setSearchQuery(newValue);
    
    if (newValue === '' && searchQuery.trim() !== '') {
      setParams((prev:any)=>{
        delete prev['search[customer_name]'];
        delete prev['search[author.name]'];
        return prev;
      })
    }
};

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

const handleCloseMenu = () => {
  setAnchorEl(null);
};

const handleMenuClick = (value: string) => {
  handleFilterClicked(value);
  handleCloseMenu();
};

const handlePageChange = (page:IPagination) => {
  setParams((prev)=>({...prev,page:page.page+1}));
}

const handleOrganizationSelect= (org:any) => {
  // console.log(org)
  setSelectedOrg(org);
  if(org.id === 'all'){
    setParams((prev:any)=>{
      delete prev['filters[organization]'];
      return {...prev};
    })
  }else{
    setParams((prev)=>({...prev,'filters[organization]':org.id}))
  }
}

useEffect(() => {
  // Check if there's a filter passed in the state via location
  const filterFromState = location.state?.filter;
  if (filterFromState) {
    setParams((prev)=>({...prev,'filters[status]': filterFromState}));
  }
// eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

  useEffect(() => {
    setIsPageTransition(requestLoading);
    if (!requestLoading && requestResponse?.data) {
      const flattenedData = requestResponse.data.map((item:any)=>transformData(item, config, preserve));
      setData(flattenedData);
      setReqData(flattenedData);  // Update reqData with the fetched request data
    }
    return () => setIsPageTransition(false);
  }, [requestLoading, requestResponse, setIsPageTransition]);

  return (
    <Box sx={{ padding: 3 }}>
      <Box sx={{ display: "flex", justifyContent: selectedRowIds.length < 2 ? "space-between" : "flex-end", alignItems: "center", mb: 2 }}>
        {selectedRowIds.length < 2 ? (
          <Box sx={{display:'flex',justifyContent:'space-between',width:'100%'}}>
            <APTableFilter
              showFilters
              showOrganizationFilter
              filterItems={userFilters}
              filterAction={handleOwnerClicked}
              onOrganizationSelect={handleOrganizationSelect}
            />
            <Box sx={{display:'flex', alignItems:'center', justifyContent:'flex-end', width:'100%', gap:1}}>
              <Box sx={{ position: 'relative' }}>
                <IconButton
                  color="primary"
                  aria-label="filter"
                  onClick={handleOpenMenu}
                  sx={{
                    bgcolor: 'primary.main',
                    color: 'white',
                    '&:hover': {
                      bgcolor: 'primary.dark'
                    }
                  }}
                >
                  <MaterialSymbol
                        icon={'filter_alt'}
                        size={24}
                    />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleCloseMenu}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  {STATUS_FILTERS.map((item:StatusFilterItem) => (
                    <MenuItem key={item.value} onClick={() => handleMenuClick(item.value)}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
              <Box>
                <form onSubmit={handleSearchSubmit}>
                    <SearchInput
                        name={'requestSearch'}
                        value={searchQuery} // Bind input value to state
                        onChange={handleSearchInputChange}
                        isLoading={isRefetching}
                    />
                    <button type="submit" style={{ display: 'none' }}>Search</button>
                </form>
              </Box>
              <Box >
                <APIconButton
                  text={'New request'}
                  onClick={() => {
                    // if(!selectedOrg){
                    //   toast.warn('Please select an organization');
                    //   return;
                    // }
                    navigate("/new-request");
                  }}
                  icon={<AddIcon />}
                />
              </Box>
            </Box>

          </Box>
        ) : (
          <APIconButton
            text="Move selected to trash"
            isRounded
            color="secondary"
            icon={<MaterialSymbol icon="delete" />}
            onClick={handleMultipleDelete}
          />
        )}
      </Box>

      <RequestTable 
        data={{rowData: data, rowCount: requestResponse?.meta.total}} 
        // isLoading={requestLoading} 
        params={params}
        rowPerPage={10} 
        onRowSelection={handleRowSelection} 
        onDelete={handleSingleDelete} 
        onPageChange={handlePageChange}
      />
      <DeleteConfirmationDialog
        title="Move to trash"
        content={contentText}
        open={openDeleteDialog}
        onCancel={handleCancelDelete}
        onDelete={handleDeleteRequest}
        isDeleting={isRequestDeleting||isBulkDeleting}
      />

      <Outlet />
    </Box>
  );
};

export default RequestsPage;
