import { CustomAxiosRequestConfig, appAPI } from "../../httpClient";
import { IOrganizationFormValues } from "./organizationInterfaces";

export const getOrganizations = async(params?:any)=>{
    const res = await appAPI.get('/organizations',{params});
    return {data:res.data.data.values, meta:res.data.data.meta};
};
export const getOrganization = async(orgId:string)=>appAPI.get(`/organizations/${orgId}`);
export const postOrganization = async(data:IOrganizationFormValues)=>{
    // const temData = {name:data.name,};
    const formData = new FormData();
    formData.append('organization[name]', data.name);
    formData.append('owner', data.owner.value);
    // formData.append('organization[default_estimate_options]',JSON.stringify(data.default_estimate_options))
    data.default_estimate_options.forEach(type => {
        formData.append('organization[default_estimate_options][]', type);
    });
    if (data.file instanceof File) {
        formData.append('organization[image]', data.file);
    }

    return appAPI.post('/organizations',formData,{
        headers:{
            'Content-Type': 'multipart/form-data', 
        }
    } as CustomAxiosRequestConfig);
}
export const patchOrganization = async(orgId:string,data:IOrganizationFormValues)=>{
    const formData = new FormData();

    if (data.name) {
        formData.append('organization[name]', data.name);
    }

    if (data.owner?.value) {
        formData.append('organization[owner]', data.owner.value);
    }

    if (data.default_estimate_options?.length) {
        // formData.append('organization[default_estimate_options]',JSON.stringify(data.default_estimate_options))
        data.default_estimate_options.forEach(type => {
            formData.append('organization[default_estimate_options][]', type);
        });
    }

    // Check if file is a File instance before appending
    if (data.file instanceof File) {
        formData.append('organization[image]', data.file);
    }


    return appAPI.patch(`/organizations/${orgId}`,formData,{
        headers:{
            'Content-Type': 'multipart/form-data', 
        }
    } as CustomAxiosRequestConfig);
}
export const deleteOrganization = async(orgId:string)=>appAPI.delete(`/api/v1/organizations/${orgId}`);