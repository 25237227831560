import { Box, IconButton, InputAdornment, MenuItem, Paper, Popper, TextField, useTheme } from "@mui/material";
import RequestInfo from "./RequestInfo";
import AssetsTableActions from "./AssetsTableActions";
import AssetsTable from "./AssetsTable";
import { useState, useRef, useEffect } from "react";
import { APIconButton } from "../../components/buttons";
import { MaterialSymbol } from "react-material-symbols";
import AssetSearchModal from "./AssetSearchModal";
// import { useLocation } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { bulkDeleteAsset, deleteAsset, getAssets } from "./assetsQueries";
import { useRequestStore } from "../requests/requestStore";
import DeleteConfirmationDialog from "../../components/dialogs/DeleteConfirmationDialog";
import { toast } from "react-toastify";
import { handleError } from "../../utils/errorHandler";
import { IPagination } from "../../interfaces";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getRequest } from "../requests/requestQueries";
import { flattenObject } from "../../utils/jsonObjectNormalize";
import { createReport } from "../reports/reportQueries";
import WarningDialog from "../../components/dialogs/WarningDialog";
import { Config, transformData } from "../../utils/transformData";
import { ASSETS_DATA } from "../../data/assetsData";
import { ASSET_HEADERS } from "../../Constant";
import { useCategoryStore } from "../../hooks/useCategoryStore";

const assetFilters: any[] = [
    { id: "all", value: "All", label: "All" },
    { id: "sub-1", value: "sub-1", label: "Sub-package 1" },
    { id: "sub-2", value: "sub-2", label: "Sub-package 2" },
    { id: "sub-3", value: "sub-3", label: "Sub-package 3" },
];

const config: Config[] =[
    {key:'id', field:'id'},
    {key:'image',field:'thumbnail'},
    {key:'year', field:'year'},
    {key:'make', field:'make'},
    {key:'model', field:'model'},
    {key:'vin', field:'vin'},
    {key:'category', field:'category'},
    {key:'serial', field:'serial'},
    {key:'sticker', field:'sticker'},
    {key:'other_estimates', field:'other_estimates'},
    {
        key: 'fmv',
        field: 'estimates[].value',
        lookup: 'estimate_type',
        isOptional: true,
    },
    {
        key: 'flv',
        field: 'estimates[].value',
        lookup: 'estimate_type',
        isOptional: true,
    },
    {
        key: 'olv',
        field: 'estimates[].value',
        lookup: 'estimate_type',
        isOptional: true,
    },
    {
        key: 'mov',
        field: 'estimates[].value',
        lookup: 'estimate_type',
        isOptional: true,
    },
    {
        key: 'hammer_estimate',
        field: 'hammer_estimate',
        isOptional: true,
    },
    {
        key: 'estimate_options',
        field: 'request.estimate_options',
    },
    {key:'media_count', field:'media_count'},
    {key:'status', field:'status'},
  ]

const AssetsPage:React.FC = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const [request, setRequest] = useState<any>();
    const storeSelectedRequest = useRequestStore(state=>state.storeSelectedRequest);
    const { categories, fetchCategories } = useCategoryStore();
    const [contentText, setContentText] = useState("");
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState<any>(null);
    const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
    const [data,setData] = useState<any[]>([]);
    const [subPackages, setSubPackages] = useState(assetFilters);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isCreatingNew, setIsCreatingNew] = useState(false);
    const [newPackage, setNewPackage] = useState("");
    const [openSearchPlus, setOpenSearchPlus] = useState(false);
    const [params, setParams] = useState<any>({limit:10,});
    const { requestId } = useParams<{ requestId: string }>();
    const [openModal, setOpenModal] = useState(false);
    const [openColumnModal, setOpenColumnModal] = useState(false);
    
    const { data: requestReponse, isLoading, refetch:refetchRequest } = useQuery(
        {
          queryKey:['request', requestId], 
          queryFn:() => getRequest(requestId as string), 
          enabled: !!requestId,
        }
      );

    const { data: requestData,refetch, isRefetching } = useQuery(
        {
          queryKey:['assets', requestId], 
          queryFn:() => getAssets({'filters[request]':requestId,...params}), 
          enabled: !!requestId,
        }
      );

    const { mutate: deleteAssettMutation, isPending: isAssetDeleting } = useMutation({
    mutationFn: (assetId: string) => deleteAsset(assetId),
        onSuccess: () => {
            toast.success('Successfully moved to trash');
            refetch();
            refetchRequest();
            setOpenDeleteDialog(false);
        },
        onError: handleError
    });

    const { mutate: bulkDeleteAssettMutation, isPending: isBulkDeleting } = useMutation({
    mutationKey:['bulkDeleteAssets', ...selectedRowIds],
    mutationFn: (formValues:any) => bulkDeleteAsset(formValues),
        onSuccess: () => {
            toast.success('Successfully moved to trash');
            refetch();
            refetchRequest();
            setOpenDeleteDialog(false);
        },
        onError: handleError
    });

    const { isPending: isCreatingReporting, mutate: createReportMutation } = useMutation({
    mutationKey: ['create report'],
    mutationFn: (requestId: any) => createReport(requestId),
    onSuccess: (res,variables) => {
    //   console.log('variables: ',variables);
    //   console.log('res: ',res.data);
        //show modal retrieving modal
        if(res.data.request.process==='normal'){
        navigate(`/reports/standard`,{state:{reportResponse:res.data,from:location.pathname}})
        }
        else if(res.data.request.process==='uspap'){
        navigate(`/reports/uspap`,{state:{reportResponse:res.data}})
        }
    //   navigate(`/reports`,{state:{reportResponse:res.data}})
    },
    onError: handleError,
    });
    
    const addSubPackageButtonRef = useRef<HTMLButtonElement | null>(null);

    const handleRowSelection = (item: any, details: any) => {
        setSelectedRowIds(item);
    };

    const handleFilterClicked = (val: any) => {
        console.log(val);
    };

    // const handleAddSubPackageClick = () => {
    //     setIsDropdownOpen(!isDropdownOpen);
    //     setIsCreatingNew(false);  // Reset create new state if previously active
    // };

    const handleCreateNewClick = () => {
        setIsCreatingNew(true);
    };

    const handleNewPackageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewPackage(e.target.value);
    };

    const handleNewPackageSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter" && newPackage.trim() !== "") {
            const newSubPackage = { id: `sub-${subPackages.length}`, value: newPackage, label: newPackage };
            setSubPackages([...subPackages, newSubPackage]);
            setIsCreatingNew(false);
        }
    };

    const handleClickAway = () => {
        setIsDropdownOpen(false);
    };

    const handleCancelDelete = () => {
        setSelectedRow(null);
        setOpenDeleteDialog(false);
      };

    const handleMultipleDelete = () => {
        setContentText("Are you sure you want to move these assets to trash?");
        setOpenDeleteDialog(true);
    }
    const handleSingleDelete = (row: any) => {
        // Set selected row and open the delete confirmation dialog
        setSelectedRow(row);
        setContentText("Are you sure you want to move this asset to trash?");
        setOpenDeleteDialog(true);
      };

      const handleDeleteAsset = () => {
        if (selectedRowIds.length >= 2) {
          bulkDeleteAssettMutation({assets:selectedRowIds});
        } else {
          deleteAssettMutation(selectedRow?.id);
        }
      };

    const handlePageChange = (page:IPagination) => {
        setParams((prev:any)=>({...prev,page:page.page+1}));
    }

    const handleReportAction = () => {
        if(request.status === 'completed'){
            createReportMutation(request.id);
          }
          else{
            setOpenModal(true);
          }
    }

    const handleColumnModalClose = () => {
        setOpenColumnModal(false);
    }

    const handleAssetSearch = (val:any) => {
        delete val['select-field'];
        if(Object.keys(val).length <= 0) {
            setOpenSearchPlus(false);
            return;
        }
        const searchParams:any = {};
        Object.keys(val).forEach((key:string)=>{
            if(key === 'assetType') searchParams[`search[category]`]=val[key].label;
            else if (key === 'status') searchParams[`search[${key}]`]=val[key].value;
            else searchParams[`search[${key}]`]=val[key];
        });
        setParams((prev:any)=>({...prev,...searchParams}));
        setOpenSearchPlus(false);
    }

    useEffect(() => {
        if (requestData) {
          const res = requestData.data
            .map((item: any) => transformData(item,config))
            .map((item: any) => {
              const meters = item.meters; // `meters` is already parsed
              const milesEntry = Array.isArray(meters)
                ? meters.find((meter: any) => meter.units === "Miles")
                : null;
              const milesValue = milesEntry && milesEntry.value ? milesEntry.value : null; // Default to "0" if no value or it's empty
      
              return {
                ...item,
                miles: milesValue, // Add the miles value to the flattened object
              };
            });
      
          setData(res);
        }
      }, [requestData]);
      
      

      useEffect(() => {
          refetch(); // Refetch only when params change
      }, [params, refetch]);

      useEffect(()=>{
        if(requestReponse){
            setRequest(requestReponse);
            storeSelectedRequest(requestReponse);
        }

      },[requestReponse, storeSelectedRequest])

      useEffect(() => {
        if(categories.length===0) fetchCategories();
      }, [categories.length, fetchCategories]);

    return (
        <Box>
            {request && <RequestInfo request={request} />}
            <Box sx={{ mt: "20px", padding: theme.spacing(3) }}>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: selectedRowIds.length < 2 ? "space-between" : "flex-end",
                        alignItems: "center",
                        marginBottom: "10px",
                    }}
                >
                    {selectedRowIds.length < 2 && (
                        <AssetsTableActions 
                            filters={subPackages} 
                            isReportLoading={isCreatingReporting} 
                            onReportAction={handleReportAction} 
                            onFilterItemsAction={handleFilterClicked} 
                            onSearch={()=>setOpenSearchPlus(true)} 
                            onColumnAction={()=>setOpenColumnModal(true)}
                        />
                    )}
                    {selectedRowIds.length >= 2 && (
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                            <APIconButton
                                text="Move to trash"
                                isRounded
                                color={"secondary"}
                                icon={<MaterialSymbol icon="delete" />}
                                onClick={handleMultipleDelete}
                                isLoading={isBulkDeleting}
                            />
                            {/* <Box ref={addSubPackageButtonRef}>
                                <APIconButton
                                    text="Add sub-package"
                                    isRounded
                                    whiteBackground
                                    icon={<MaterialSymbol icon="add" />}
                                    onClick={handleAddSubPackageClick}
                                />
                            </Box> */}
                            <Popper
                                open={isDropdownOpen}
                                anchorEl={addSubPackageButtonRef.current}
                                placement="bottom-start"
                                disablePortal
                                sx={{ zIndex: 1 }}
                            >
                                <Paper sx={{borderRadius:'16px',p:2}}>
                                    <Box sx={{ minWidth: 183, borderRadius:'16px' }}>
                                        {subPackages.filter((pkg)=>pkg.value!=='All').map((pkg) => (
                                            <MenuItem key={pkg.id} value={pkg.value} onClick={()=>handleClickAway()}>
                                                {pkg.label}
                                            </MenuItem>
                                        ))}
                                        {!isCreatingNew && <MenuItem sx={{backgroundColor:'#EBF1FC', borderRadius:'8px'}} onClick={handleCreateNewClick}>Create new</MenuItem>}
                                        {isCreatingNew && <MenuItem sx={{}}>
                                        <TextField
                                            autoFocus
                                            fullWidth
                                            size="small"
                                            placeholder="Enter sub-package name"
                                            value={newPackage}
                                            onChange={handleNewPackageChange}
                                            onKeyDown={handleNewPackageSubmit}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={() => handleNewPackageSubmit({ key: "Enter" } as React.KeyboardEvent<HTMLInputElement>)}>
                                                            <MaterialSymbol icon="keyboard_return" /> {/* Enter icon */}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        </MenuItem>}
                                    </Box>
                                </Paper>
                            </Popper>
                            <APIconButton
                                text={"Report"}
                                onClick={() => {}}
                                icon={
                                    <Box sx={{ transform: "rotate(-45deg)" }}>
                                        <MaterialSymbol icon="send" />
                                    </Box>
                                }
                            />
                        </Box>
                    )}
                </Box>
                <Box sx={{ mb: 1 }} />
                    <AssetsTable 
                        onRowSelection={handleRowSelection} 
                        data={{rowData:data, rowCount:requestData?.meta.total}} 
                        onDelete={handleSingleDelete} 
                        isLoading={isRefetching || isLoading}
                        onPageChange={handlePageChange}
                        params={{...params, total:requestData?.meta.total}}
                        openColumnModal={openColumnModal}
                        onCloseColumModal={handleColumnModalClose}
                    />
            </Box>
            <AssetSearchModal open={openSearchPlus} onClose={()=>{
                    setParams((prev:any)=>{
                        ASSET_HEADERS.forEach((header:any) => delete prev[`search[${header.value}]`])
                        delete prev['search[category]'];

                        return {...prev};
                    });
                    setOpenSearchPlus(false);
                }} 
                onSubmit={handleAssetSearch}
            />
            <DeleteConfirmationDialog
                title="Move to trash"
                content={contentText}
                open={openDeleteDialog}
                onCancel={handleCancelDelete}
                onDelete={handleDeleteAsset}
                isDeleting={isAssetDeleting}
            />
            <WarningDialog 
                open={openModal}
                title="Warning"
                content="Some assets in this report have missing values. Please review the data or proceed to generate the report as is."
                onCancel={()=>{
                    setOpenModal(false);
                }}
                onProceed={()=>{
                    setOpenModal(false);
                    createReportMutation(request.id);
                }}
            />
        </Box>
    );
};

export default AssetsPage;
