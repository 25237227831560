import { Box } from "@mui/material";
import { IColDef, IColumnConfig } from "../../components/table/tableInterfaces";
import APTable from "../../components/table/APTable";
import AdditionalInfoModal from "./AdditionalInfoModal";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IPagination, ITableData } from "../../interfaces";
import ColumnEnhancementModal from "../../components/dialogs/column-enhancement/ColumnEnhancementModal";
import { useGeneralStore } from "../../generaStore";
import { GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid-pro";

interface Props{
    isLoading?: boolean;
    data: ITableData;
    rowPerPage?:number;
    params?:any;
    openColumnModal: boolean;
    onRowSelection?:(item:any,details:any) => void;
    onDelete?:(row:any)=>void;
    onPageChange?: (page:IPagination) => void;
    onCloseColumModal?:()=>void;
}

const DEFAULT_COLUMN_CONFIG:IColumnConfig = {
    columnHide: {}, 
    pinnedColumns: {
        left: [GRID_CHECKBOX_SELECTION_COL_DEF.field], 
        right: []
    }, 
    columnOrder: [],
    isDefault: true
};

const AssetsTable: React.FC<Props> = ({ isLoading, data, rowPerPage, params, openColumnModal, onRowSelection, onDelete, onPageChange, onCloseColumModal }) => {
    const navigate = useNavigate();
    const {assetColumnConfig:columnConfigStore, setAssetColumnConfig:setColumConfigStore} = useGeneralStore();
    const [selectedRow, setSelectedRow] = useState<any>();
    const [openAdditionalInfoModal, setOpenAdditionalInfoModal] = useState(false);

    const [columnConfig, setColumnConfig] = useState<IColumnConfig>(columnConfigStore);

    const [enhancementChange,setEnhancementChanged] = useState(false);

    const colDef: IColDef[] = useMemo(() => {
        let defaultColumns: IColDef[] = columnConfigStore.columnOrder?.length > 0 
            ? [...columnConfigStore.columnOrder] 
            : [
                { id: 1, field: 'image', headerName: 'Image', type: 'imageText2', imageField: 'image', sortable: false, filterable: false, width: 150 },
                { id: 2, field: 'year', headerName: 'Year', filterable: false, width: 100 },
                { id: 3, field: 'make', headerName: 'Make', filterable: false, width: 200 },
                { id: 4, field: 'model', headerName: 'Model', filterable: false, width: 200 },
                { id: 5, field: 'vin', headerName: 'VIN', filterable: false, width: 200 },
                { id: 6, field: 'category', headerName: 'Asset type', filterable: false, width: 250 },
                { id: 7, field: 'serial', headerName: 'Serial', filterable: false, sortable: false, width: 200 },
                { id: 8, field: 'sticker', headerName: 'Sticker ID', filterable: false, sortable: false, width: 200 },
                { id: 9, field: 'media_count', headerName: 'Media', filterable: false, sortable: false, width: 100 },
                { id: 10, field: 'other_estimates', headerName: 'Other estimates', type:'other_estimates', filterable: false, sortable: false, width: 170 },
            ];
        const extraColumns = [
            { field: 'status', headerName: 'Status', type: 'status', filterable: false, sortable: false, width:140},
            { field: 'actions', headerName: 'Actions', type: 'action', filterable: false, width:120}
        ];
            
        defaultColumns = defaultColumns.filter(col => !['fmv', 'flv', 'olv', 'mov'].includes(col.field));
        defaultColumns = defaultColumns.filter(col => !extraColumns.some(extra => extra.field === col.field));
        defaultColumns = defaultColumns.filter(col => col.field !== 'hammer_estimate');
    
        const estimateOptions = data.rowData[0]?.estimate_options || [];
        estimateOptions.forEach((estimate: string) => {
            if (!defaultColumns.some(col => col.field === estimate)) {
                defaultColumns.push({
                    id: defaultColumns.length + 1,
                    field: estimate,
                    headerName: estimate.toUpperCase(),
                    filterable: false,
                    sortable: false,
                    width: 150
                });
            }
        });
    
        if(estimateOptions.includes('mov')){
            defaultColumns.push({
                id: defaultColumns.length + 1,
                field: 'hammer_estimate',
                headerName: 'Hammer estimate',
                filterable: false,
                sortable: false,
                width: 150
            });
        }

      extraColumns.forEach((item: any) =>defaultColumns.push({id:defaultColumns.length+1,...item}));

        return defaultColumns;
    }, [data.rowData,columnConfigStore.columnOrder]);

    const actions = [
        {
            label: 'Request additional info',
            action: (row: any) => {
                setSelectedRow(row);
                setOpenAdditionalInfoModal(true);
            }
        },
        {
            label: 'Compare with others',
            action: (row: any) => {
                // navigate(`/compare/${row.id}`);
            }
        },
        {
            label: 'Generate report',
            action: (row: any) => {}
        },
        {
            label: 'Edit asset',
            action: (row: any) => {
                // console.log(row)
                const {limit,page,total}=params;
                const currentIndex = data.rowData.findIndex((item)=>item.id === row.id)
                const currentPage = page || 1;
                const currentNumber = (currentPage - 1) * (limit || 0) + currentIndex + 1;
                navigate(`/edit-asset/${row.id}`,
                    {state: { currentNumber, total }}
                );
                // navigate(`/edit-asset/${row.id}`);
            }
        },
        {
            label: 'MOVE ASSET TO TRASH',
            action: (row: any) => {
                if (onDelete) onDelete(row);
            }
        }
    ];

    const clickableFields = ['image', 'year', 'make', 'model', 'category', 'serial', 'sticker', 'miles', 'media_count', 'status'];

    const handleRowClick = (row: any) => {
        if (clickableFields.includes(row.field)) {
            const {limit,page,total}=params;
            const currentIndex = data.rowData.findIndex((item)=>item.id === row.data.id)
            const currentPage = page || 1;
            const currentNumber = (currentPage - 1) * (limit || 0) + currentIndex + 1;
            navigate(`/edit-asset/${row.data.id}`,
                {state: { currentNumber, total }}
            );
        }
    };

    const handleOnCancel = () => {
        setSelectedRow(null);
        setOpenAdditionalInfoModal(false);
    };

    const filteredData = data.rowData.map((item) => {
        return { ...item };
    });

    const handleColumnVisibilityChange = (field: string) => {
        setColumnConfig((prev: any) => {
            const updatedColumnHide = { ...prev.columnHide };
            
            if (Object.keys(updatedColumnHide).includes(field)) {
                delete updatedColumnHide[field];
            } else {
                updatedColumnHide[field] = false;
            }
    
            return {
                ...prev,
                columnHide: updatedColumnHide,
            };
        });

        setEnhancementChanged(true)
    };

    const handleColumnPinning = (field: string, position: 'left' | 'right' | 'unpin') => {
        setColumnConfig((prev: any) => {
            const updatedPinnedColumns = { ...prev.pinnedColumns };
            
            if (position === 'unpin') {
                updatedPinnedColumns.left = updatedPinnedColumns.left.filter((col:string) => col !== field);
                updatedPinnedColumns.right = updatedPinnedColumns.right.filter((col:string) => col !== field);
            } else {
                updatedPinnedColumns.left = updatedPinnedColumns.left.filter((col:string) => col !== field);
                updatedPinnedColumns.right = updatedPinnedColumns.right.filter((col:string) => col !== field);
                
                updatedPinnedColumns[position].push(field);
            }
            
            return {
                ...prev,
                pinnedColumns: updatedPinnedColumns,
            };
        });
        setEnhancementChanged(true)
    };
    
    const handleSaveColumsConfigChanges = () => {
        if(enhancementChange){
            setColumConfigStore({...columnConfig, isDefault:false});
            setColumnConfig({...columnConfig, isDefault:false});
        }
        if(onCloseColumModal) onCloseColumModal();
    }

    const handleCancelColumnsConfigChanges = () => {
        setColumnConfig(columnConfigStore);
        if(onCloseColumModal)onCloseColumModal();
    }
    
    const handleColumnOrderChanged = (columns: IColDef[]) => {
        setColumnConfig((prev: any) => ({
            ...prev,
            columnOrder: columns,
        }));
        setEnhancementChanged(true)
    }

    const handleResetToDefault = () => {
        setColumConfigStore(DEFAULT_COLUMN_CONFIG);
        setColumnConfig(DEFAULT_COLUMN_CONFIG);
        if(onCloseColumModal) onCloseColumModal();
    }
    
    return (
        <Box>
            <APTable
                showCheckbox
                columns={colDef}
                data={{ rowData: filteredData, rowCount: data.rowCount }}
                actions={actions}
                clickableFields={clickableFields}
                rowPerPage={10}
                onRowSelection={onRowSelection}
                onRowClick={handleRowClick}
                isLoading={isLoading}
                rowHeight={90}
                onPageChange={onPageChange}
                columnVisibilityModel={columnConfig.columnHide}
                pinnedColumns={columnConfig.pinnedColumns}
            />
            <AdditionalInfoModal open={openAdditionalInfoModal} row={selectedRow} onCancel={handleOnCancel} />
            <ColumnEnhancementModal 
                open={openColumnModal} 
                columns={colDef} 
                columnConfig={columnConfig} 
                onColumnVisibilityChange={handleColumnVisibilityChange} 
                onColumnPiningChange={handleColumnPinning}
                onSaveColumsConfigChanges={handleSaveColumsConfigChanges} 
                onClose={handleCancelColumnsConfigChanges}
                onColumnOrderChanged={handleColumnOrderChanged}
                onResetToDefault={handleResetToDefault}
            />
        </Box>
    );
};

export default AssetsTable;
