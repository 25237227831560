import { useEffect, useMemo, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import APDivider from "../../../components/APDivider";
import {
  LabelCheckboxInput,
  RadioInput,
  RichTextInput,
  SelectInput,
} from "../../../components/inputs";
import {
  CAPTURE_MODE_DATA,
  EVALUATION_REASONS,
  PROCESS_DATA,
} from "../../../data/selectData";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import LabelInput from "../../../components/inputs/LabelInput";
import { useForm } from "react-hook-form";
import { isEmail, isRequired } from "../../../utils/validationRules";
import { useFetchUsers } from "../../../hooks/useFetchUsers";
import { IUser } from "../../auth/authInterfaces";
import FileInput from "../../../components/inputs/FileInput";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { PostRequestVariables, RequestFormValues } from "../requestInterfaces";
import { postRequest } from "../requestQueries";
import { toast } from "react-toastify";
import { handleError } from "../../../utils/errorHandler";
import { VALUATION_TYPES } from "../../../Constant";
import { useFetchOrganizations } from "../../../hooks/useFetchOrganizations";
import { IOrgSelect } from "../../../components/OrganizationSelector";
import { IOrganization } from "../../organization-manageent/organizationInterfaces";
import useNavigationConfirmationHook from "../../../components/dialogs/useNavigationConfirmationHook";

const RequestForm = () => {
  const navigate = useNavigate();

  const [selectedOrg, setSelectedOrg] = useState<{
    id?: string;
    label?: string;
    value?: string;
    name?: String;
    default_estimate_options: string[];
  }>({ default_estimate_options: [] });
  // console.log(selectedOrg);
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { dirtyFields },
  } = useForm<RequestFormValues>();
  const { data: response, isPending: isUsersLoading } = useFetchUsers({
    limit: 5000000,
    "filters[role]": "appraiser",
  });
  const [resetForm, setResetForm] = useState(false);
  const [usersData, setUsersData] = useState([]);
  const { data: organizations } = useFetchOrganizations();
  const [disableValuation, setDisableValuation] = useState(true);

  const orgData = useMemo(() => {
    const fetchedOrgs: IOrgSelect[] =
      organizations?.data?.map((org: IOrganization) => ({
        id: org.id,
        label: org.name,
        value: org.id,
        name: org.name,
        default_estimate_options: org.default_estimate_options,
        logo: org.logo,
      })) || [];
    return [...fetchedOrgs];
  }, [organizations]);

  const primaryAppraiser = watch("manager");
  const organization: any = watch("organization");

  const {
    isPending: isLoading,
    isSuccess,
    mutate: postRequestMutation,
  } = useMutation({
    mutationKey: ["postRequestMutation"],
    retry: 2,
    mutationFn: ({ formValues }: PostRequestVariables) =>
      postRequest(formValues),
    onSuccess: (res, variables) => {
      console.log('variablessss',variables);
      if (res.status === 204) {
        toast.error("An error occured during request creation");
      } else {
        toast.success("Request created successfully");
        reset();
        if (variables.actionType === "create") {
          navigate("/requests");
        } else if (variables.actionType === "createAnother") {
          setResetForm(true);
          setTimeout(() => setResetForm(false), 0);
        }
      }
    },
    onError: handleError,
  });

  // hook for checking navigation when fields are dirty
  const { confirmDialogue } = useNavigationConfirmationHook({
    isDirty: Object.keys(dirtyFields).length > 0 && !isSuccess,
  });

  const onSubmitWithActionType = (actionType: "create" | "createAnother") => {
    return (data: RequestFormValues) => {
      let payload: any = {};

      (Object.keys(data) as (keyof RequestFormValues)[]).forEach((key) => {
        if (data[key] !== undefined && data[key] !== null) {
          switch (key) {
            case "estimate_options":
              payload[key] = [
                ...data[key],
                ...selectedOrg.default_estimate_options,
              ];
              break;
            case "valuation_reasons":
              payload[key] = Array.isArray(data[key])
                ? data[key].map((item) => item.id)
                : data[key];
              break;
            case "manager":
              payload[key] = data[key].id;
              break;
            case "collaborators":
              if (Array.isArray(data[key]) && data[key].length > 0) {
                payload[key] = data[key].map((item) => item.id); // Extracting IDs
              } else {
                payload[key] = data[key] || [];
              }
              break;
            case "fileData":
              payload["assets"] = data[key].tableData;
              break;
            default:
              payload[key] = data[key];
          }
        }
      });

      delete payload.default_estimate_options;
      // console.log(payload);

      postRequestMutation({
        formValues: {
          ...payload,
          organization: selectedOrg.id,
          // default_estimate_options:selectedOrg.default_estimate_options
        },
        actionType,
      });
    };
  };

  const handleFormSubmit = (type: "create" | "createAnother") => {
    handleSubmit(onSubmitWithActionType(type))();
  };

  useEffect(() => {
    if (response) {
      setUsersData(
        response.data.map((user: IUser) => ({
          id: user.id,
          label: user.name,
          value: user.id,
        }))
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUsersLoading]);

  useEffect(() => {
    if (organization) {
      setSelectedOrg(organization);
      setDisableValuation(false);
    }
  }, [organization]);

  useEffect(()=>{
    if(organization){
      setSelectedOrg(organization);
      setDisableValuation(false);
    }
  },[organization]);

  return (
    <Box sx={{ padding: (theme) => theme.spacing(3) }}>
      <Box>
        <form>
          {/* Form contents */}
          <Box>
            <APDivider text="Who owns this equipment?" />
            <Box
              sx={{ display: "flex", width: "50%", gap: 3, paddingTop: "15px" }}
            >
              <SelectInput
                id="organization"
                label="Organization name*"
                name="organization"
                options={orgData}
                control={control}
                rules={{ validate: { isRequired } }}
              />
              <LabelInput
                id="customerName"
                label="Owner Name*"
                name="customer_name"
                control={control}
                rules={{ validate: { isRequired } }}
              />
              <LabelInput
                id="customerEmail"
                label="Owner email"
                name="customer_email"
                control={control}
                rules={{ validate: { isEmail } }}
              />
            </Box>
          </Box>

          <Box>
            <APDivider text="Valuation details" />
            <Box
              sx={{ display: "flex", width: "50%", gap: 3, paddingTop: "15px" }}
            >
              <LabelCheckboxInput
                label="Default valuation types"
                name="default_estimate_options"
                control={control}
                readOnly
                disabled={disableValuation}
                options={VALUATION_TYPES.filter((val: any) =>
                  selectedOrg.default_estimate_options.includes(val.value)
                )}
              />
            </Box>
            <LabelCheckboxInput
              label="Other valuation types (Note: These are not default to your organization)"
              name="estimate_options"
              control={control}
              disabled={disableValuation}
              options={VALUATION_TYPES.filter(
                (val: any) =>
                  !selectedOrg.default_estimate_options.includes(val.value)
              )}
            />
            <RadioInput
              label="Select Process"
              name="process"
              control={control}
              options={PROCESS_DATA}
            />
            <RadioInput
              label="Capture type"
              name="capture_mode"
              control={control}
              options={CAPTURE_MODE_DATA}
            />
          </Box>

          <Box sx={{ marginTop: "38px" }}>
            <APDivider text="More information" />
            <Box sx={{ display: "flex", width: "24.5%", paddingTop: "15px" }}>
              <SelectInput
                id="reasons"
                label="Select evaluation reasons"
                name="valuation_reasons"
                options={EVALUATION_REASONS}
                isMultiple
                control={control}
              />
            </Box>
          </Box>

          <Box sx={{ marginTop: "38px" }}>
            <APDivider text="Notes" />
            <Box sx={{ width: "100%", paddingTop: "15px" }}>
              <RichTextInput id="notes" name="notes" control={control} />
            </Box>
          </Box>

          <Box sx={{ marginTop: "38px" }}>
            <APDivider text="Who is managing this request?" />
            <Box sx={{ width: "24.5%", paddingTop: "15px" }}>
              <SelectInput
                id="primaryAppraiser"
                label="Primary appraiser*"
                name="manager"
                options={usersData}
                control={control}
                rules={{ validate: { isRequired } }}
              />
              <SelectInput
                id="collaborators"
                label="Collaborators"
                name="collaborators"
                options={usersData.filter(
                  (item: any) => item.id !== primaryAppraiser?.id
                )}
                control={control}
                isMultiple
              />
            </Box>
          </Box>

          <Box sx={{ marginTop: "38px" }}>
            <APDivider text="Add assets" />
            <Box sx={{ height: "120px", paddingTop: "15px" }}>
              <FileInput
                label="Drag and drop or choose an Excel file"
                name="fileData"
                control={control}
                type="excel"
                reset={resetForm}
              />
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
            }}
          >
            <Box>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleFormSubmit("create")}
                disabled={isLoading}
              >
                Create request
              </Button>
              <Button
                onClick={() => {
                  navigate("/requests");
                }}
                variant="outlined"
                sx={{ ml: 1 }}
              >
                Cancel
              </Button>
            </Box>

            <Button
              variant="text"
              color="primary"
              disabled={isLoading}
              endIcon={<ArrowForwardIosIcon />}
              onClick={() => handleFormSubmit("createAnother")}
            >
              Save and create another
            </Button>
          </Box>
        </form>
      </Box>
      {confirmDialogue}
    </Box>
  );
};

export default RequestForm;
