import { IConfirmEmail, IForgotPasswordForm, ILoginForm, IResetPasswordForm } from './authInterfaces';
import { authAPI } from '../../httpClient';

export const login = async (formValues: ILoginForm, signal?: AbortSignal) => {
  const response = await authAPI.post('/auth/login',formValues);
  const {token,user} = response.data.data;
  const result = {
    token,
    user,
    data:user
  }
  // console.log('query result: ', result);
  return result;
}
export const forgotPassword = async (formValues: IForgotPasswordForm) => {
  const res = await authAPI.post('/auth/forgot_password', formValues);
  return res.data
};
export const resetPassword = async (formValues: IResetPasswordForm) => authAPI.post('/auth/reset_password', formValues);
// export const setPassword = async (formValues: IResetPasswordForm) => authAPI.post('/users/accept_invite', formValues,{ applyAuth: false} as CustomAxiosRequestConfig);
export const confirmEmail = async (formValues: IConfirmEmail) => authAPI.post('/confirm-email', formValues);
export const logout = async () => authAPI.post('/logout',{});