import MuiPagination from '@mui/material/Pagination';
import {
  GridPagination,
  gridPageCountSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-pro';

import { TablePaginationProps } from '@mui/material/TablePagination';

interface PaginationProps extends Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'> {
  apiRef: any; // Accept `apiRef` from parent
}

function Pagination({ page, onPageChange, className, apiRef }: PaginationProps) {
  const pageCount = useGridSelector(apiRef, gridPageCountSelector); // Get total page count

  return (
    <MuiPagination
      color="primary"
      className={className}
      count={pageCount}
      page={page + 1} // Convert 0-based index to 1-based for display
      onChange={(event, newPage) => {
        onPageChange(event as any, newPage - 1); // Convert back to 0-based for DataGrid
      }}
    />
  );
}

export function APTablePagination(props: any) {
  const apiRef = useGridApiContext(); // Get API reference here
  return <GridPagination ActionsComponent={(paginationProps) => <Pagination {...paginationProps} apiRef={apiRef} />} {...props} />;
}
