// useCategoryStore.ts
import { create } from 'zustand';
import { fetchAndTransformData, CategoryOption } from '../services/categoriesDataService';
import { persist } from 'zustand/middleware';

interface CategoryState {
  categories: CategoryOption[];
  lastFetchedDate: string | null;
  fetchCategories: () => Promise<void>;
}

const getTodayDate = () => {
  const today = new Date();
  return today.toISOString().split('T')[0]; // Returns "YYYY-MM-DD"
};

export const useCategoryStore = create(
  persist<CategoryState>(
    (set, get) => ({
      categories: [],
      lastFetchedDate: null,
      fetchCategories: async () => {
        const today = getTodayDate();
        const lastFetched = get().lastFetchedDate;

        // Check if data was already fetched today
        if (lastFetched === today && get().categories.length > 0) {
          // console.log("Data already fetched today, loading from store.");
          return;
        }

        // Otherwise, fetch new data from the API
        const transformedData = await fetchAndTransformData();
        set({
          categories: transformedData,
          lastFetchedDate: today, // Update the last fetched date
        });
      },
    }),
    {
      name: 'category-storage', // name of the persisted state in local storage
      // getStorage: () => localStorage, // Use localStorage to persist the Zustand state
    }
  )
);
