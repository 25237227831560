import React, { useEffect, useState } from "react";
import { Modal, Box, Typography, Button } from "@mui/material";
import { useAuthStore } from "../../features/auth/authStore";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

interface LogoutConfirmationModalProps {
  open: boolean;
  //   if you want to perform something when you close the modal by cancelling
  onCancel: (val?: any) => void;
  //   if you want to perform something when you logout
  onLogout: (val?: any) => void;
}

const LogoutConfirmationModal = ({
  open,
  onCancel,
  onLogout,
}: LogoutConfirmationModalProps) => {
  const handleCancel = () => {
    onCancel?.();
  };

  const handleLogout = () => {
    onLogout?.();
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Logout</DialogTitle>
      <DialogContent
        sx={{
          maxWidth: "380px",
        }}
      >
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to logout?
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "left",
          paddingX:2,
          paddingBottom:2,
          gap:1
        }}
      >
        <Button
          onClick={handleLogout}
          variant="text"
          color="secondary"
          sx={{ border: "1px solid red" }}
        >
          Yes, log me out.
        </Button>
        <Button onClick={handleCancel} color="primary" variant="contained">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LogoutConfirmationModal;
